<template>
  <div
    v-if="myStatus"
    class="d-flex align-items-center align-self-center
      status-indicator r-25 overflow-hidden"
  >
    <b-dropdown
      id="my-status"
      size="sm"
      boundary="window"
      no-caret
      variant="muted"
      class="w-100"
      dropright
      :title="$t('message.setStatus')"
      toggle-class="text-light text-truncate"
    >
      <template #button-content>
        <status-indicator
          :status="myStatus"
          class="d-inline-block mr-1"
        />
        {{ statusText }}
      </template>
      <b-dropdown-header>{{ $t('message.setStatus') }}</b-dropdown-header>
      <b-dropdown-item
        v-for="option in statusOptions"
        :key="option.value"
        :disabled="myStatus === option.value"
        @click="handleStatusChange(option.value)"
      >
        {{ option.text }}
      </b-dropdown-item>
    </b-dropdown>
    <b-popover
      ref="statusPopover"
      target="my-status"
      triggers="manual"
      boundary="viewport"
    >
      <template #title>
        <div class="d-flex justify-content-between align-items-center">
          <span>{{ $t('message.invisibleReminderTitle') }}</span>
          <b-button
            variant="muted"
            class="ml-auto text-muted"
            :title="$t('vocabulary.close')"
            size="sm"
            @click="$refs.statusPopover.$emit('close')"
          >
            <font-awesome-icon icon="times" />
          </b-button>
        </div>
      </template>
      {{ $t('message.invisibleReminderContent') }}
      <div>
        <b-button
          variant="link"
          size="sm"
          class="pl-0"
          @click="handleStatusChange('ON')"
        >
          {{ $t('message.invisibleReminderButton') }}
        </b-button>
      </div>
    </b-popover>
    <b-modal
      id="last-online-agent-modal"
      @hide="resetMyStatus"
      @ok="handleStatusChange('IN', true)"
    >
      {{ $t('message.invisibleWarn') }}
    </b-modal>
  </div>
</template>

<script>
import { throttle } from 'lodash';
import {
  mapGetters,
  mapState,
  mapActions,
  mapMutations,
} from 'vuex';

import { chatEvent } from 'supwiz/supchat/constants';

import StatusIndicator from '@/components/Sidebar/StatusIndicator.vue';

export default {
  name: 'LoginStatus',
  components: {
    StatusIndicator,
  },
  data() {
    return {
      inactivityInterval: null,
      maintainStatusInterval: null,
    };
  },
  computed: {
    ...mapGetters('agent', ['displayName', 'id']),
    ...mapGetters('status', ['myStatus', 'getAgentStatus', 'getMinInactivityTimeout']),
    ...mapGetters('systemAgents', ['systemAgents']),
    ...mapState('controlSocket', ['controlSocket']),
    ...mapState('templateStore', ['sidebarCompact']),
    ...mapState('controlSocket', ['controlSocketStatus']),
    statusOptions() {
      return [
        {
          value: 'ON',
          text: this.$t('vocabulary.online'),
        },
        {
          value: 'AW',
          text: this.$t('vocabulary.away'),
        },
        {
          value: 'IN',
          text: this.$t('vocabulary.invisible'),
        },
      ];
    },
    statusText() {
      const options = this.statusOptions;
      const status = this.myStatus;
      return options.find(({ value }) => value === status)?.text || '';
    },
  },
  watch: {
    myStatus(status) {
      /*
        Small performance watcher.
        We only need to check for inactivity as long as the status
        is online. Otherwise, there's no need.
      */
      clearInterval(this.inactivityInterval);
      if (status === 'ON') {
        localStorage.setItem('lastActivityTimestamp', new Date().getTime());
        this.inactivityInterval = setInterval(this.handleInactivity, 5000);
      }
    },
  },
  async created() {
    await Promise.all([this.ensureAgentsFetched(), this.getStatusOverview(),
      this.fetchInactivityTimeouts()]);
    this.setLastChosenStatus(this.getLastChosenStatus());
    this.maintainStatusInterval = setInterval(this.maintainStatus, 4000);
    this.activityHandler();
    if (this.myStatus === 'IN') {
      if (this.$refs.statusPopover) this.$refs.statusPopover.$emit('open');
      setTimeout(() => {
        if (this.$refs.statusPopover) this.$refs.statusPopover.$emit('close');
      }, 10000);
    }
  },
  beforeDestroy() {
    clearInterval(this.inactivityInterval);
    clearInterval(this.maintainStatusInterval);
  },
  methods: {
    ...mapActions('status', ['setAgentStatus', 'getStatusOverview']),
    ...mapActions('systemAgents', ['ensureAgentsFetched']),
    ...mapActions('status', ['fetchInactivityTimeouts']),
    ...mapMutations('status', ['SET_AGENT_STATUS']),
    async handleInactivity() {
      if ((new Date()
        .getTime() - Number(localStorage.getItem('lastActivityTimestamp'))) >= this.getMinInactivityTimeout) {
        this.changeMyStatus('AW');
      }
    },
    handleStatusChange(status, fromLastAgentModal = false) {
      this.$refs.statusPopover.$emit('close');
      if (status !== 'IN') {
        this.changeMyStatus(status);
        this.setLastChosenStatus(status);
        return;
      }
      const agents = this.systemAgents.map((agent) => ({
        ...agent,
        status: this.getAgentStatus(agent.id),
      }));
      const onlineAgents = agents.filter((agent) => agent.status !== 'IN' && agent.status !== undefined);
      const lastAgentOnline = onlineAgents.filter((a) => a.id !== this.id).length === 0;
      if (!fromLastAgentModal && lastAgentOnline) {
        this.$root.$emit('bv::show::modal', 'last-online-agent-modal');
      } else {
        this.changeMyStatus(status);
        this.setLastChosenStatus(status);
      }
    },
    resetMyStatus(BvModalEvent) {
      // no need to reset if the ok button was clicked
      if (BvModalEvent.trigger === 'ok') return;
      /*
        There must be another way to change back the value of the input-select
        but I give up for now.
      */
      const curStatus = this.myStatus;
      this.SET_AGENT_STATUS({ agentId: this.id, status: curStatus === 'ON' ? 'AW' : 'ON' });
      setTimeout(() => {
        this.SET_AGENT_STATUS({ agentId: this.id, status: curStatus });
      }, 100);
    },
    changeMyStatus(status) {
      try {
        this.controlSocket.send(JSON.stringify({ type: chatEvent.MAINTAIN_STATUS }));
      } catch (err) {
        // When the supchat window is initialized in the browser `this.controlSocket.send` may
        // give an error because the control socket is not initialized. An uninitialized control
        // socket is equivalent to the agent being offline so we simply ignore the error.
      }
      this.setAgentStatus({ agentId: 'me', status });
    },
    maintainStatus() {
      if (this.myStatus === 'IN') return;
      if (this.controlSocketStatus === 1) {
        this.controlSocket.send(JSON.stringify({ type: chatEvent.MAINTAIN_STATUS }));
      }
    },
    updateActivity() {
      localStorage.setItem('lastActivityTimestamp', new Date().getTime());
      // We only want to change the status back to online if it was not
      // changed manually and if it's actually set to away.
      if (this.getLastChosenStatus() !== this.myStatus) {
        setTimeout(() => {
          this.changeMyStatus(this.getLastChosenStatus());
        }, 1000);
      }
    },
    handleActivity() {
      return throttle(this.updateActivity, 2500, { trailing: false });
    },
    activityHandler() {
      // This is where we detect activity and then run handleActivity to
      // update status and update lastActivityTimestamp
      window.onload = this.handleActivity();
      window.onmousemove = this.handleActivity();
      window.ontouchstart = this.handleActivity();
      window.onclick = this.handleActivity();
      window.onkeypress = this.handleActivity();
      window.onwheel = this.handleActivity();
    },
    getLastChosenStatus() {
      const storedStatus = localStorage.getItem('lastChosenStatus');
      const valid = ['ON', 'AW', 'IN'];

      // not yet initialized
      if (!valid.includes(storedStatus)) {
        if (!valid.includes(this.myStatus)) {
          this.setLastChosenStatus('IN');
        } else {
          this.setLastChosenStatus(this.myStatus);
        }
      }
      return localStorage.getItem('lastChosenStatus');
    },
    setLastChosenStatus(status) {
      localStorage.setItem('lastChosenStatus', status);
    },
  },
};
</script>

<style scoped>
.status-indicator {
  height: 30px;
  max-width: 100px;
  background: rgba(0,0,0,0.225);
}
</style>
