<template>
  <div>
    <floating-container
      class="p-2"
      no-body
    >
      <filter-component
        :filters="['timeRange', 'agentTenants', 'agentDepartments', 'systemAgents']"
        prepend-labels
        inline
        date-picker-type="minute"
        @ready="updateParams"
        @input="({
          timeRange, agentTenants, systemAgents, agentDepartments,
        }) => {
          timeSlot = timeRange;
          tenantSelectedArray = agentTenants;
          agentSelectedArray = systemAgents;
          departmentSelectedArray = agentDepartments;
        }"
      >
        <b-input-group
          v-if="tagOptions.length"
          :prepend="$tc('vocabulary.tag', 1)"
        >
          <multi-select
            id="tagSelect"
            v-model="tagSelectedArray"
            searchable
            typename="vocabulary.tag"
            :options="tagOptions"
          />
        </b-input-group>
        <b-input-group
          :prepend="$tc('vocabulary.visitor', 1)"
          class="flex-grow-1"
        >
          <label
            class="sr-only"
          >{{ $tc('vocabulary.visitor', 1) }}</label>
          <b-form-input
            v-model="visitorSearch"
            :placeholder="$t('message.archiveSearchVisitor')"
            @keyup.enter.prevent="updateParams"
          />
        </b-input-group>
        <b-input-group
          :prepend="$t('vocabulary.content')"
          class="flex-grow-1"
        >
          <label
            class="sr-only"
          >{{ $t('vocabulary.content') }}</label>
          <b-form-input
            v-model="contentSearch"
            :placeholder="$t('message.archiveSearchContent')"
            @keyup.enter.prevent="updateParams"
          />
        </b-input-group>
        <template v-if="tenantSelectedArray.length === 1">
          <b-input-group
            v-if="ratingOptions[tenantSelectedArray[0]]"
            :key="`${tenantSelectedArray[0]}rating`"
            :prepend="$t('vocabulary.rating')"
            class="flex-grow-1"
          >
            <label
              class="sr-only"
            >
              {{ $t('vocabulary.rating') }} {{ $parent.lookupTenantName(tenantSelectedArray[0]) }}
            </label>
            <b-form-select
              v-model="ratingSearch[tenantSelectedArray[0]]"
              :options="ratingOptions[tenantSelectedArray[0]]"
              placeholder="Search by rating"
              @keyup.enter.prevent="updateParams"
            />
          </b-input-group>
        </template>
      </filter-component>
    </floating-container>
    <floating-container
      no-body
      class="p-2 d-inline-block"
    >
      <b-btn
        variant="success"
        class="mr-2"
        @click="updateParams"
      >
        {{ $t('vocabulary.search') }}
      </b-btn>
      <smart-b-button
        v-if="exportAllowed"
        variant="primary"
        class="align-items-center d-inline-flex"
        :busy="preparingData"
        @click="$refs.exportModal.show()"
      >
        {{ $t('message.analytics.export') }}
      </smart-b-button>
    </floating-container>
    <b-modal
      ref="exportModal"
      :title="$t('message.archive.exportTitle')"
      :ok-title="$t('vocabulary.download')"
      :cancel-title="$t('vocabulary.cancel')"
      @ok="exportData"
    >
      <b-form-group
        :label="$t('vocabulary.format')"
        :description="$t('message.archive.exportFormatDesc')"
      >
        <b-form-select
          v-model="exportConfig.format"
          :options="$options.exportSheetFormats"
        />
      </b-form-group>
      <b-form-group
        v-if="exportConfig.format === 'csv'"
        :label="$t('vocabulary.delimiter')"
        :description="$t('message.archive.exportDelimiterDesc')"
      >
        <b-form-select
          v-model="exportConfig.delimiter"
          :options="$options.exportSheetDelimiters"
        />
      </b-form-group>
      <b-form-group
        :label="$tc('vocabulary.column', 2)"
        :description="$t('message.archive.exportColumnsDesc')"
      >
        <b-form-checkbox-group
          v-model="exportConfig.columns"
          :options="exportColumns"
          stacked
        />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import { exportSheetFormats, exportSheetDelimiters } from '@/utils/constants';
import { mapGetters } from 'vuex';
import { exportArchiveChats } from '@/api/apiList';
import { getTimezone } from '@/utils/generalUtils';

import MultiSelect from '@/components/MultiSelect.vue';

export default {
  name: 'ArchiveForm',
  components: { MultiSelect },
  props: {
    tagOptions: {
      type: Array,
      required: true,
    },
    ratingOptions: {
      type: Object,
      required: true,
    },
    managedTenants: {
      type: Array,
      required: true,
    },
  },
  exportSheetFormats,
  exportSheetDelimiters,
  data() {
    return {
      tenantSelectedArray: [],
      agentSelectedArray: [],
      departmentSelectedArray: [],
      tagSelectedArray: [],
      visitorSearch: '',
      contentSearch: '',
      ratingSearch: {},
      timeSlot: [],
      preparingData: false,
      exportConfig: {
        format: 'xlsx',
        delimiter: '\t',
        columns: [
          'ended_at',
          'started_at',
          'tenant',
          'department_transfer_history',
          'agents',
          'visitor',
          'id',
        ],
      },
    };
  },
  computed: {
    ...mapGetters('agent', ['departments']),
    params() {
      return {
        timeSlot: this.timeSlot,
        tenantSelectedArray: this.tenantSelectedArray,
        agentSelectedArray: this.agentSelectedArray,
        departmentSelectedArray: this.departmentSelectedArray,
        tagSelectedArray: this.tagSelectedArray,
        contentSearch: this.contentSearch,
        visitorSearch: this.visitorSearch,
        ratingSearch: this.ratingSearch,
      };
    },
    exportColumns() {
      const columns = [
        { value: 'ended_at', text: 'ended_at' },
        { value: 'started_at', text: 'started_at' },
        { value: 'tenant', text: 'tenant' },
        { value: 'department_transfer_history', text: 'department_transfer_history' },
        { value: 'agents', text: 'agents' },
        { value: 'visitor', text: 'visitor' },
        { value: 'id', text: 'id' },
      ];
      if (this.tagOptions.length) {
        columns.push({ value: 'tags', text: 'tags' });
      }
      if (this.ratingEnabled) {
        columns.push({ value: 'rating', text: 'rating' }, { value: 'rating_comment', text: 'rating_comment' });
      }
      return columns;
    },
    validation() {
      const result = {};
      result.timeSlot = this.timeSlot.length === 0 || this.timeSlot[0] !== this.timeSlot[1];
      result.allValid = Object.values(result).every(Boolean);
      return result;
    },
    ratingEnabled() {
      return this.tenantSelectedArray.length === 1
        && this.ratingOptions[this.tenantSelectedArray[0]];
    },
    exportAllowed() {
      return this.tenantSelectedArray.every((x) => this.managedTenants.includes(x));
    },
  },
  created() {
    if (this.tagOptions.length) {
      this.exportConfig.columns.push('tags');
    }
  },
  methods: {
    updateParams() {
      if (this.validation.allValid) {
        this.$emit('updateParams', this.params);
      } else {
        this.displayErrors();
      }
    },
    async exportData() {
      if (!this.validation.allValid) {
        this.displayErrors();
        return;
      }
      try {
        this.preparingData = true;
        const params = {
          tenants: this.tenantSelectedArray,
          interval_start: this.timeSlot[0] / 1000,
          interval_end: this.timeSlot[1] / 1000,
          agent: this.agentSelectedArray,
          department: this.departmentSelectedArray.map((depId) => {
            const match = this.departments.find((dep) => dep.id === depId);
            if (match) return match.name;
            return depId;
          }),
          tags: this.tagSelectedArray,
          content: this.contentSearch,
          visitor: this.visitorSearch,
          timezone: getTimezone(),
          output_format: this.exportConfig.format,
          delimiter: this.exportConfig.delimiter,
          columns: this.exportConfig.columns,
        };
        const keys = Object.keys(params);
        for (const key of keys) {
          if (!params[key]
              || (typeof params[key] === 'object' && Object.entries(params[key]).length === 0)) {
            if (key !== 'tenants') delete params[key];
          }
        }
        if (params.tenants?.length === 1 && this.ratingSearch[params.tenants[0]] !== null) {
          params.rating = this.ratingSearch[params.tenants[0]];
        }
        const response = await exportArchiveChats(params);
        const blob = await response.blob();
        saveAs(blob, `supchat-archive.${this.exportConfig.format}`);
      } catch (error) {
        this.$bvToast.toast(this.$t('message.analytics.exportError'), {
          title: this.$t('message.analytics.exportFailed'),
          autoHideDelay: 7500,
          variant: 'danger',
        });
        this.$log.error(error);
      } finally {
        this.preparingData = false;
      }
    },
    displayErrors() {
      if (!this.validation.timeSlot) {
        this.$bvToast.toast(this.$t('message.archive.timeBody'), {
          title: this.$t('message.archive.timeTitle'),
          autoHideDelay: 5000,
          variant: 'danger',
        });
      }
    },
  },
};
</script>
