var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"d-flex flex-column",class:{
    'align-items-end': _vm.msgRole === _vm.myRole,
    'align-items-start': _vm.msgRole !== _vm.myRole,
  },staticStyle:{"transition":"opacity .3s ease-in-out!important"},style:({ opacity: _vm.ack ? '1' : '0.8' })},[(_vm.displaySender)?_c('span',{staticClass:"sender",style:(_vm.senderOffset),attrs:{"aria-hidden":"true"}},[_vm._v(" "+_vm._s(_vm.msgName.length > 24 ? _vm.msgName.slice(0, 23) + '...' : _vm.msgName)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex chat-bubble-wrap align-items-center",class:`${_vm.msgRole !== _vm.myRole ? 'flex-row-reverse' : ''} ${_vm.senderId}`},[_c('div',{staticClass:"d-flex flex-column justify-content-center justify-content-around message-actions"},[(!_vm.ack)?_c('button',{staticClass:"msg-error",attrs:{"type":"button","title":_vm.$t('status.msgSendFail')},on:{"click":function($event){return _vm.resendMessage(_vm.timestamp)}}},[_c('svg',{staticStyle:{"cursor":"pointer!important"},attrs:{"aria-hidden":"true","focusable":"false","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512","width":"15","height":"15"}},[_c('title',[_vm._v(_vm._s(_vm.$t('status.msgSendFail')))]),_c('path',{attrs:{"fill":"#000","d":"M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248\n            248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532\n            0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471\n            200 200 0 110.53-89.431 200-200 200zm42-104c0 23.159-18.841\n            42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42zm-81.37-211.401l6.8\n            136c.319 6.387 5.591 11.401 11.985 11.401h41.17c6.394 0 11.666-5.014\n            11.985-11.401l6.8-136c.343-6.854-5.122-12.599-11.985-12.599h-54.77c-6.863\n            0-12.328 5.745-11.985 12.599z"}})])]):_vm._e(),_vm._t("message-aside")],2),_c('span',{staticClass:"chat-bubble",class:_vm.borderRadiusClasses},[_vm._t("default")],2),(_vm.senderAvatar)?_c('user-avatar',_vm._b({style:({ visibility: _vm.displaySender ? 'visible!important' : 'hidden!important' }),attrs:{"aria-hidden":"true"}},'user-avatar',{
        senderAvatar: _vm.senderAvatar,
        borderRadius: _vm.borderRadius,
        msgName: _vm.msgName,
      },false)):_vm._e()],1),(_vm.displayTimestamp)?_c('span',{staticClass:"timestamp",style:(_vm.senderOffset),attrs:{"aria-hidden":"true","title":_vm.timestampLocaleString}},[_vm._v(" "+_vm._s(_vm.timestampClock)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }