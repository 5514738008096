<template>
  <div
    class="position-relative bg-white ease-in-out wrapper d-flex flex-column"
    :style="inputFieldWrapStyles"
  >
    <div class="position-relative flex-grow-1">
      <CannedMessageMatcher
        v-if="hasSentStartMsg && !isEnded"
        ref="CannedMessageMatcher"
        v-bind="{
          autocompleteMsg,
          chatId,
          departmentId,
          inputFocus: inputFocused,
          tenantId,
        }"
      />
      <b-form-textarea
        id="inputField"
        ref="inputField"
        v-model="message"
        autofocus
        maxlength="1000"
        no-resize
        spellcheck="true"
        :class="[{
          'bg-translationpreview': isTranslationPreviewMode,
          'bg-note': isNoteMode,
          'bg-light': isEnded,
        }, 'h-100 r-25 pb-3']"
        :disabled="!hasSentStartMsg || isEnded"
        :placeholder="inputPlaceholder"
        @blur="updateInputFocus(false)"
        @focus="updateInputFocus(true)"
        @input.native="onTextareaInput"
        @keydown.enter.native="handleSendMessage"
        @keydown.esc="() => $refs.CannedMessageMatcher.keepClosed = true"
        @keydown="autocompleteMsg"
        @paste="textareaPasteFileHandler"
      />
    </div>
    <div class="d-flex border action-wrap r-25">
      <slot name="actions-left" />
      <template v-if="!isEnded && hasSentStartMsg">
        <div class="d-flex ml-auto">
          <b-button
            v-if="isTranslationPreviewMode"
            class="text-decoration-none text-right border-left border-left-radius-0"
            size="sm"
            variant="link"
            :title="$t('message.translationPreviewGoBack')"
            @click="undoPreviewTranslation"
          >
            {{ $t('message.translationPreviewGoBack') }}
            <font-awesome-icon class="ml-1" icon="language" />
          </b-button>
          <template v-else>
            <FileUpload
              v-if="!isNoteMode"
              ref="fileUploadElement"
              v-slot="fileUpload"
              v-bind="{ chatId }"
              class="border-left border-right border-left-radius-0 border-right-radius-0"
              tooltip-target="upload-btn"
            >
              <smart-b-button
                id="upload-btn"
                class="h-100"
                size="sm"
                variant="link"
                :busy="fileUpload.busy"
                :disabled="isNoteMode"
                :title="$t('message.chatModal.fileUpload.sendFile')"
                @click="fileUpload.openDialog()"
              >
                <font-awesome-icon icon="paperclip" />
              </smart-b-button>
            </FileUpload>
            <GptResponse
              v-if="gptEnhanceReplyEnabled"
              class="border-right border-right-radius-0 border-left-radius-0"
              :disabled="messageType !== 'chat'"
              v-bind="{
                chatId,
                language: isTranslationPreviewMode ? visitorLanguage : agentLanguage,
              }"
              @generated-reply="sendTextToInput"
            />
            <LanguageAndTranslation
              v-if="!isEnded && messageType !== 'note'"
              v-bind="{
                agentLanguage,
                autoTranslationEnabled,
                chatId,
                departmentId,
                tenantId,
                visitorLanguage,
              }"
              :disabled="messageType !== 'chat' || !hasSentStartMsg"
              class="border-right border-right-radius-0"
              @set-language="$emit('set-language', $event)"
              @toggle-translation="$emit('toggle-translation', $event)"
            />
            <PreviewTranslationButton
              v-if="translationsEnabled
                && (visitorLanguage !== agentLanguage)
                && messageType === 'chat'"
              :chat-id="chatId"
              :disabled="!message"
              class="border-right border-right-radius-0 border-left-radius-0"
              size="sm"
              variant="link"
              @result="handlePreviewTranslation"
            />
            <b-button
              class="px-2 border-right border-right-radius-0"
              size="sm"
              variant="link"
              :disabled="messageHistoryIndex >= messageHistory.length - 1"
              @click="undoMessageFromHistory"
            >
              <font-awesome-icon icon="arrow-rotate-left" />
            </b-button>
            <b-button
              class="px-2 border-right border-right-radius-0"
              size="sm"
              variant="link"
              :disabled="messageHistoryIndex <= 0"
              @click="redoMessageFromHistory"
            >
              <font-awesome-icon icon="arrow-rotate-left" flip="horizontal" />
            </b-button>
            <b-button
              class="text-decoration-none message-type-toggle text-right"
              size="sm"
              variant="link"
              :title="$t(`message.chatModal.messageTypes.${messageType}`)"
              @click="toggleMessageTypeNote"
            >
              {{ $t(`message.chatModal.messageTypes.${messageType}`) }}
              <font-awesome-icon class="ml-1" :icon="messageType === 'chat' ? 'comment' : 'note-sticky'" />
            </b-button>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { chatEvent } from 'supwiz/supchat/constants';
import Handlebars from 'handlebars/dist/cjs/handlebars';
import {
  isHandlebarsTemplate,
  sendMessage,
  sendCommand,
} from 'supwiz/supchat/generalUtils';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'MessageTextarea',
  components: {
    GptResponse: () => import('./GptResponse.vue'),
    CannedMessageMatcher: () => import('./CannedMessageMatcher.vue'),
    FileUpload: () => import('@/components/FileUpload.vue'),
    LanguageAndTranslation: () => import('./LanguageAndTranslation.vue'),
    PreviewTranslationButton: () => import('./PreviewTranslationButton.vue'),
  },
  inject: {
    $translationsEnabled: {
      from: 'translationsEnabled',
    },
  },
  provide() {
    return {
      processCannedMsg: this.processCannedMsg,
      sendTextToInput: this.sendTextToInput,
      getFieldSelection: this.getFieldSelection,
      inputRefFunc: () => this.$refs.inputField,
    };
  },
  props: {
    chatId: {
      type: String,
      required: true,
    },
    departmentId: {
      type: String,
      required: true,
    },
    agentLanguage: {
      type: String,
      required: true,
    },
    visitorLanguage: {
      type: String,
      required: true,
    },
    tenantId: {
      type: String,
      required: true,
    },
    autoTranslationEnabled: {
      type: Boolean,
      required: true,
    },
    metaDataForVariables: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      inputFocused: false,
      inputInitialHeight: 0,
      inputScrollHeight: 0,
      messageHistory: [],
      messageHistoryIndex: -1,
      translatedOriginal: '',
      untranslatedOriginal: '',
      messageType: 'chat',
    };
  },
  computed: {
    ...mapState(['featureFlags']),
    ...mapState('chat', ['chatSocket']),
    ...mapGetters('chat/chatModals', ['getModalValue']),
    ...mapGetters('tenants', ['getConfig']),
    translationsEnabled() { return this.$translationsEnabled(); },
    generalConfig() { return this.getConfig({ tenantId: this.tenantId, tag: 'general' }); },
    appendCannedMsg() {
      return this.generalConfig ? this.generalConfig
        .append_canned_message : false;
    },
    message: {
      get() {
        return this.getModalValue({ chatId: this.chatId, field: 'input' }) || '';
      },
      set(message) {
        this.setInput({ chatId: this.chatId, message });
      },
    },
    gptEnhanceReplyEnabled() {
      return this.featureFlags.AI_COPILOT_GPT_ENABLED;
    },
    hasSentStartMsg() { return this.$store.getters['chat/isChatCurrentlyStarted'](this.chatId); },
    isEnded() { return this.$store.getters['chat/hasChatBeenClosed'](this.chatId); },
    inputFieldWrapStyles() {
      const result = {
        height: '100%',
        marginTop: '0rem',
      };
      const bufferHeight = this.inputInitialHeight * 1.1;
      if (this.inputScrollHeight > bufferHeight && this.inputFocused && this.message) {
        result.height = 'calc(100% + 10rem)';
        result.marginTop = '-10rem';
      }
      return result;
    },
    inputPlaceholder() {
      if (this.isEnded || !this.hasSentStartMsg) return '';
      if (this.isNoteMode) return this.$t('message.chatModal.msgInputPlaceholderNote');
      return this.$t('message.chatModal.msgInputPlaceholder');
    },
    isTranslationPreviewMode() {
      return this.messageType === 'preview';
    },
    isNoteMode() {
      return this.messageType === 'note';
    },
  },
  methods: {
    ...mapActions('chat/chatModals', ['setInput']),
    autocompleteMsg(event) {
      const key = event.key;
      const isTab = key === 'Tab';
      const isAltIndex = /[1-9]/.test(key) && event.altKey;
      if (!isTab && !isAltIndex) return;
      const matcherComponent = this.$refs.CannedMessageMatcher;
      if (!matcherComponent) return;
      const matches = matcherComponent.displayedMatches;
      if (!matches.length) return;
      let cannedMessage = null;
      if (isTab) {
        const match = matches?.[0];
        if (match) cannedMessage = match;
      } else {
        const match = matches?.[key];
        if (match) cannedMessage = match;
      }
      if (!cannedMessage) return;
      event.preventDefault();
      this.processCannedMsg(cannedMessage?.content || '');
    },
    processCannedMsg(textToProcess) {
      let result = textToProcess;
      if (isHandlebarsTemplate(result)) {
        const templateCM = Handlebars.compile(result);
        result = templateCM(this.metaDataForVariables());
      }
      if (this.appendCannedMsg) {
        this.sendTextToInput(this.message + result, true);
      } else this.sendTextToInput(result, true);
    },
    getFieldSelection() {
      const result = {
        selectionStart: this.message.length,
        selectionEnd: this.message.length,
        selectedText: this.message,
        hasSelection: false,
      };
      const inputField = this.$refs.inputField;
      if (inputField) {
        const { selectionStart, selectionEnd } = inputField.$el;
        result.selectionStart = selectionStart;
        result.selectionEnd = selectionEnd;
        result.hasSelection = selectionStart < selectionEnd;
        if (result.hasSelection) {
          result.selectedText = this.message.slice(selectionStart, selectionEnd);
        }
      }
      return result;
    },
    sendTextToInput(text, replaceAll = false) {
      if (!text) return;
      if (this.isTranslationPreviewMode) {
        this.undoPreviewTranslation();
      }
      this.sendMessageToHistory();
      if (replaceAll) this.message = text;
      else {
        let result = text;
        const {
          selectionStart,
          selectionEnd,
          hasSelection,
        } = this.getFieldSelection();

        // if a selection is made, we only want to replace that
        if (hasSelection) {
          const appendText = this.message.substring(0, selectionStart);
          const prependText = this.message.substring(selectionEnd);
          result = appendText + text + prependText;
        }
        this.message = result;
        this.$nextTick(() => {
          if (hasSelection) {
            this.$refs.inputField.setSelectionRange(selectionStart, selectionStart + text.length);
          }
          this.focusInputField();
        });
      }
    },
    sendMessageWrapper() {
      const isNote = this.isNoteMode;
      let text = this.message.trim();
      let msgLanguage = this.agentLanguage;
      if (this.isTranslationPreviewMode) {
        const originalText = this.untranslatedOriginal.trim();
        const originalTranslatedText = this.translatedOriginal.trim();
        if (text === originalTranslatedText) {
          text = originalText;
        } else {
          msgLanguage = this.visitorLanguage;
        }
      }
      if (text === '' || this.chatSocket.readyState !== 1) return;
      if (isNote) {
        this.sendCommandWrapper({
          command: chatEvent.NOTE,
          text,
        });
      } else {
        this.$emit('answered-chat', this.chatId);
        sendMessage(this.chatSocket, text, this.chatId, msgLanguage);
        this.appendUnAckMsg(this.chatId, text);
      }
      this.message = '';
      this.exitPreviewMode();
      this.messageHistoryIndex = -1;
      this.messageHistory = [];
      this.focusInputField();
    },
    handleSendMessage(ev) {
      if (ev.shiftKey || this.chatSocket.readyState !== 1) return;
      const disableSendWithEnter = sessionStorage.getItem('disableSendWithEnter') === 'true';
      if (!disableSendWithEnter) {
        ev.preventDefault();
        this.sendMessageWrapper();
      }
    },
    exitPreviewMode() {
      if (this.isTranslationPreviewMode) this.messageType = 'chat';
    },
    toggleMessageTypeNote() {
      const newType = this.messageType === 'chat' ? 'note' : 'chat';
      this.messageType = newType;
    },
    onTextareaInput(event) {
      this.$emit('input', event);
      const target = event.target;
      const scrollHeight = target.scrollHeight;
      if (!this.message) {
        this.inputScrollHeight = 0;
        this.exitPreviewMode();
        this.translatedOriginal = '';
        this.untranslatedOriginal = '';
      } else if (scrollHeight !== this.inputScrollHeight) this.inputScrollHeight = scrollHeight;
      this.clearForwardHistory();
    },
    undoPreviewTranslation() {
      this.message = this.untranslatedOriginal;
      this.untranslatedOriginal = '';
      this.translatedOriginal = '';
      this.exitPreviewMode();
      this.focusInputField();
    },
    handlePreviewTranslation(translatedText) {
      this.messageType = 'preview';
      this.untranslatedOriginal = this.message;
      this.translatedOriginal = translatedText;
      this.message = translatedText;
      this.focusInputField();
    },
    async textareaPasteFileHandler(event) {
      if (!event.clipboardData.files.length) {
        return;
      }
      const file = event.clipboardData.files[0];
      if (file) {
        const textData = event.clipboardData?.getData?.('text/plain');
        if (textData) {
          // our "file" is actually just text with extra metadata
          return;
        }
        event.preventDefault();
        const uploadEl = this.$refs.fileUploadElement;
        if (uploadEl) uploadEl.handleFile(file);
      }
    },
    sendCommandWrapper(cmd) {
      sendCommand(this.chatSocket, this.chatId, cmd);
    },
    appendUnAckMsg(chatId, text) {
      this.$store.dispatch('chat/appendUnAckMsg', { chatId, text });
    },
    focusInputField() {
      this.$nextTick(() => {
        const inputField = this.$refs.inputField;
        if (inputField) this.$refs.inputField.focus();
        this.$nextTick(() => {
          // check if we managed to focus it, otherwise try again
          if (document.activeElement.id !== 'inputField') {
            setTimeout(() => {
              this.focusInputField();
            }, 200);
          }
        });
      });
    },
    clearForwardHistory() {
      if (!this.messageHistory.length) return;

      this.messageHistory = this.messageHistory.slice(Math.max(this.messageHistoryIndex, 0));
      this.messageHistoryIndex = -1;
    },
    sendMessageToHistory() {
      if (!this.message) return;
      this.messageHistory.unshift(this.message);
      if (this.messageHistory.length > 10) {
        this.messageHistory = this.messageHistory.slice(0, 10);
      }
    },
    undoMessageFromHistory() {
      if (!this.messageHistory.length) return;
      if (this.message && this.messageHistoryIndex === -1
        && !this.messageHistory.includes(this.message)) {
        this.sendMessageToHistory();
        this.messageHistoryIndex++;
      }
      const index = this.messageHistoryIndex;
      if (index >= this.messageHistory.length - 1) return;
      this.message = this.messageHistory[index + 1];
      this.messageHistoryIndex++;
    },
    redoMessageFromHistory() {
      const index = this.messageHistoryIndex;
      if (index <= 0) return;
      this.message = this.messageHistory[index - 1];
      this.messageHistoryIndex--;
    },
    updateInputFocus(isFocused) {
      this.inputFocused = isFocused;
      if (this.inputInitialHeight === 0) {
        this.inputInitialHeight = this.$refs.inputField.$el.offsetHeight;
      }
      if (isFocused) {
        this.inputScrollHeight = this.$refs.inputField.$el.scrollHeight;
      }
    },
  },
};
</script>

<style scoped>
#inputField {
  min-height: 120px;
  border-bottom-left-radius: 0px!important;
  border-bottom-right-radius: 0px!important;
}
.bg-note {
  background-color: rgba(255, 190, 120, 0.25)!important;
}
.bg-translationpreview {
  background-color: rgba(93, 183, 222, 0.25)!important;
}
.message-type-toggle {
  width: 135px;
}
.wrapper {
  z-index:10;
}
.action-wrap {
  border-top-left-radius: 0px!important;
  border-top-right-radius: 0px!important;
  border-top: 0!important;
}
.border-right-radius-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.border-left-radius-0 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
