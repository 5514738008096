<template>
  <div>
    <div
      v-if="!ready"
      class="d-flex h-100 w-100 position-absolute justify-content-center align-items-center"
    >
      <b-spinner
        variant="muted"
        style="height:5rem;width:5rem;"
      />
    </div>
    <template v-else>
      <archive-form
        :tag-options="tagOptions"
        :rating-options="ratingOptions"
        :managed-tenants="managedTenantIds"
        @updateParams="updateParams"
      />
      <floating-container body-class="p-3">
        <div
          v-if="busyGettingChats"
          class="d-flex flex-column my-5 py-5 h-100 w-100 justify-content-center align-items-center"
        >
          <b-spinner

            variant="muted"
            style="height:5rem;width:5rem;"
          />
          <h3 class="text-muted mt-3">
            {{ $t('message.searchFetching') }}
          </h3>
        </div>
        <h3
          v-else-if="!hasSearched"
          class="my-5 py-5 text-center text-muted"
        >
          {{ $t('message.searchPress') }}
        </h3>
        <h3
          v-else-if="!chatList.length"
          class="my-5 py-5 text-center text-muted"
        >
          {{ $t('message.searchNoRes') }}
        </h3>
        <template v-else>
          <b-pagination
            v-model="currentPage"
            :total-rows="chatList.length"
            :per-page="perPage"
            align="center"
          />
          <b-table
            hover
            small
            bordered
            stacked="sm"
            sort-by="timestamp"
            :sort-desc="true"
            :current-page="currentPage"
            :per-page="perPage"
            :items="chatList"
            :fields="fields"
            class="mb-0"
          >
            <template
              #cell(tenant_id)="row"
            >
              {{ lookupTenantName(row.item.tenant_id) }}
            </template>
            <template #cell(delete)="row">
              <b-form-checkbox
                v-model="selectedChats[row.item.chat_id]"
                :disabled="isDeleteAllowed(row.item)"
              />
            </template>
            <template
              #cell(details)="row"
            >
              <div class="d-flex">
                <smart-b-button
                  size="sm"
                  class="mr-1 flex-grow-1"
                  :busy="loadingChat[row.item.chat_id]"
                  @click.stop="showTranscriptAndVisitorDetails(row)"
                >
                  {{ $tc('message.archiveConversationBtnTxt', row.detailsShowing ? 2 : 1) }}
                </smart-b-button>
                <b-button
                  size="sm"
                  @click="openArchiveSinglePage(row.item.chat_id)"
                >
                  <font-awesome-icon icon="external-link-alt" />
                </b-button>
              </div>
            </template>
            <template
              #row-details="row"
            >
              <b-card>
                <b-button
                  v-b-tooltip="$options.tooltipOptions"
                  size="lg"
                  class="mr-2"
                  style="position: absolute; right: 1rem;"
                  :title="$t('vocabulary.copy')"
                  @click.stop="copyChat(row.item.chat_id)"
                >
                  &#128462;
                </b-button>

                <b-form-textarea
                  plaintext
                  rows="2"
                  max-rows="8"
                  :value="prettyMetaString(row.item.chat_id)"
                  class="col-11"
                  style="background-color: rgba(0, 0, 0, 0.03);"
                />
              </b-card>
            </template>
          </b-table>
          <div
            v-if="managedTenantIds.length > 0"
            class="mt-2 d-flex justify-content-between w-100"
          >
            <b-form-checkbox
              v-model="deleteMode"
              switch
              class="mx-1 mt-1 d-inline-block"
            >
              {{ $t('message.deleteMode') }}
            </b-form-checkbox>
            <div v-if="deleteMode">
              <b-button
                v-if="Object.values(selectedChats).some((v) => v === true)"
                variant="danger"
                size="sm"
                class="mr-2"
                @click="deleteSelectedChat"
              >
                {{ $tc('message.deleteChats', Array.from(Object.values(selectedChats))
                  .reduce((a, b) => a + b, 0), {
                  number: Array.from(Object
                    .values(selectedChats)).reduce((a, b) => a + b, 0),
                }) }}
              </b-button>
              <b-button
                v-b-modal.confirm-delete-all
                variant="danger"
                size="sm"
              >
                {{ $t('message.deleteAllChats') }}
              </b-button>
              <b-modal
                id="confirm-delete-all"
                :title="$t('message.archive.modalTitle')"
                :ok-title="$t('message.archive.modalOK')"
                :cancel-title="$t('vocabulary.cancel')"
                ok-variant="danger"
                centered
                @ok="deleteSelectedChat('all')"
              >
                <p>{{ $t('message.archive.modalText1') }}</p>
                <b-input-group
                  v-for="(v, k) in deletionParameters"
                  :key="k"
                  size="sm"
                  :prepend="k"
                  class="mb-1"
                >
                  <b-form-input
                    readonly
                    class="bg-white"
                    :value="v"
                  />
                </b-input-group>
                <p>
                  {{ $t('message.archive.modalText2') }}
                </p>
                <p
                  v-if="!('interval_start' in deletionParameters)"
                  class="mt-3 text-danger"
                >
                  {{ $t('message.archive.modalText3') }}
                </p>
                <p class="mt-3">
                  {{ $t('message.archive.modalText4', {
                    number: Object
                      .keys(selectedChats).length,
                  }) }}
                </p>
              </b-modal>
            </div>
          </div>
        </template>
      </floating-container>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import copy from 'clipboard-copy';
import { msgsToString, metaDataObjectToString } from 'supwiz/supchat/generalUtils';
import { MANAGER_ROLE, chatEvent } from 'supwiz/supchat/constants';
import { mapActions, mapGetters } from 'vuex';
import ArchiveForm from '@/pages/archivePage/ArchiveForm.vue';
import {
  getChatsMeta,
  getChatLog,
  getVisitorInfoOfChat,
  getChatsMetaNext,
  deleteChat,
  getVisitorFileUpload,
} from '@/api/apiList';

import { tooltipOptions } from '@/utils/constants';

export default {
  name: 'ArchivePage',
  components: {
    ArchiveForm,
  },
  tooltipOptions: {
    ...tooltipOptions,
    placement: 'top',
  },
  data() {
    return {
      busyGettingChats: false,
      hasSearched: false,
      deleteMode: false,
      selectedChats: {},
      currentPage: 1,
      perPage: 10,
      chats: {},
      chatList: [],
      nextUrl: null,
      params: {},
      ratingOptions: {},
      ready: false,
      loadingChat: {},
    };
  },
  computed: {
    ...mapGetters('agent', ['tenants', 'departments', 'maxRole', 'tenantsAsManager']),
    ...mapGetters('systemAgents', { agents: 'systemAgents' }),
    ...mapGetters('tenants', { getConfig: 'getConfig', tags: 'getTags' }),
    fields() {
      return [
        {
          key: 'timestamp',
          label: this.$t('vocabulary.timestamp'),
          sortable: true,
          formatter: (s) => moment.unix(s).format('Y-MM-DD HH:mm:ss'),
          thStyle: { width: '150px' },
        },
        {
          key: 'tenant_id',
          label: this.$tc('vocabulary.tenant', 1),
          sortable: true,
          thStyle: { width: '200px' },
        },
        {
          key: 'department_transfer_history',
          label: this.$tc('vocabulary.department', 2),
          sortable: true,
          formatter: (val) => val.join(', '),
          thStyle: { width: '250px' },
        },
        {
          key: 'visitor_name',
          label: this.$t('message.visitorName'),
          sortable: true,
          formatter: (v) => (v.length > 37 ? `${v.slice(0, 36)}...` : v),
          thStyle: { width: '150px' },
        },
        {
          key: 'agents',
          label: this.$tc('vocabulary.agent', 2),
          sortable: true,
          formatter: this.agentNameFormatter,
        },
        this.tagOptions.length ? {
          key: 'tags',
          label: this.$tc('vocabulary.tag', 2),
          sortable: true,
          formatter: (tags) => tags.join(', '),
        } : undefined,
        {
          key: 'details',
          label: this.$tc('vocabulary.conversation', 1),
          sortable: false,
          thStyle: {
            width: '180px',
          },
        },
        this.deleteMode ? {
          key: 'delete',
          label: 'Delete',
          thStyle: { width: '50px' },
        } : undefined,
      ];
    },
    managedTenantIds() {
      const data = this.tenantsAsManager;
      return data.map((t) => t.id);
    },
    deletionParameters: {
      cache: false,
      get() {
        /*
          This object is used to display a summary of current parameters
          when deleting all chats and nothing more.
          The real parameters are used for deletion.
        */
        const tenants = this.tenantSelectedArray
          .filter((t) => this.managedTenantIds.includes(t));
        const agents = this.agentSelectedArray
          .map((id) => this.agentOptions.find((agent) => agent.value === id).text).join(', ');
        const tags = this.tagSelectedArray;

        const result = {
          tenants: tenants.map((id) => this.lookupTenantName(id)).join(', '),
          agents,
          tags,
          visitor: this.visitorSearch,
          content: this.contentSearch,
        };

        if (this.timeSlot.length === 2 && this.timeSlot.every((time) => typeof time === 'number')) {
          result.interval_start = new Date(this.timeSlot[0]).toLocaleString();
          result.interval_end = new Date(this.timeSlot[1]).toLocaleString();
        }

        if (this.ratingSearch !== null && this.ratingOptions[tenants[0]]) {
          // Searching by rating is disabled when multiple tenants are selected
          // So we can get away with just getting the value from the first selected tenant.
          const optionMatch = this.ratingOptions[tenants[0]]
            .find((o) => o.value === this.ratingSearch[tenants[0]]);
          if (optionMatch) result.rating = optionMatch.text;
        }

        Object.entries(result).forEach(([key, value]) => {
          if (value === '' || value === undefined) {
            delete result[key];
          }
        });
        return result;
      },
    },
    agentOptions() {
      const result = [];
      const agents = this.agents;
      agents.forEach((a) => {
        result.push({
          value: a.id,
          text: a.display_name,
        });
      });
      return result;
    },
    tagOptions() {
      const result = [];
      Object.keys(this.tags).forEach((tag) => {
        result.push({
          value: tag,
          text: `${this.tags[tag].name} (${this.tags[tag].tenant})`,
          tenant: this.tags[tag].tenant,
        });
      });
      return result;
    },
    timeSlot() { return this.params.timeSlot || []; },
    tenantSelectedArray() { return this.params.tenantSelectedArray || []; },
    departmentSelectedArray() { return this.params.departmentSelectedArray || []; },
    agentSelectedArray() { return this.params.agentSelectedArray || []; },
    tagSelectedArray() { return this.params.tagSelectedArray || []; },
    visitorSearch() { return this.params.visitorSearch || ''; },
    contentSearch() { return this.params.contentSearch || ''; },
    ratingSearch() {
      if (this.tenantSelectedArray.length === 1) {
        return this.params.ratingSearch;
      }
      return null;
    },
  },
  watch: {
    currentPage(newVal) {
      if (newVal >= Math.floor(this.chatList.length / this.perPage)
        && this.nextUrl !== null) {
        this.getChats({ keepChats: true });
      }
    },
    chatList(val) {
      for (const e of val) {
        if (!Object.prototype.hasOwnProperty.call(this.selectedChats, e.chat_id)
            && this.managedTenantIds.indexOf(e.tenant_id) !== -1) {
          this.$set(this.selectedChats, e.chat_id, false);
        }
      }
    },
  },
  async created() {
    await Promise.all([
      this.ensureTenantsFetched(),
      this.ensureAgentsFetched(),
      this.fetchTags(),
    ]);
    await this.prepareAndSetRatingOptions();
    this.ready = true;
  },
  methods: {
    ...mapActions('agent', ['ensureTenantsFetched']),
    ...mapActions('systemAgents', ['ensureAgentsFetched']),
    ...mapActions('tenants', ['fetchTags', 'fetchAndSetConfig']),
    agentNameFormatter(agents) {
      /* format the name as NNIT want to use show initials */
      const nameList = agents.map((name) => {
        if (name.includes('(') && name.includes(')')) {
          return name.split('(')[0];
        }
        return name;
      });
      return nameList.join(', ');
    },
    async getChats({ keepChats }) {
      this.busyGettingChats = true;
      try {
        if (keepChats) {
          const params = this.prepareParams();
          const chats = await getChatsMetaNext(params, this.nextUrl);
          Object.assign(this.chats, chats.results);
          this.chatList = Object.values(this.chats);
          this.nextUrl = chats.next;
        } else {
          const params = this.prepareParams();
          this.chats = {};
          this.currentPage = 1;
          const chats = await getChatsMeta(params);
          this.chats = chats.results;
          this.chatList = Object.values(this.chats);
          this.nextUrl = chats.next;
        }
        this.hasSearched = true;
      } catch (error) {
        this.$log.error(error);
      }
      this.busyGettingChats = false;
    },
    updateParams(params) {
      this.selectedChats = {};
      this.params = params;
      this.getChats({ keepChats: false });
    },
    lookupTenantName(tenantId) {
      const tenant = this.tenants.find((e) => e.id === tenantId);
      if (tenant) { return tenant.name; }
      return tenantId;
    },
    async showTranscriptAndVisitorDetails(row) {
      const chatId = row.item.chat_id;
      const shouldFetchChat = !row.detailsShowing;
      if (shouldFetchChat) {
        this.$set(this.loadingChat, chatId, true);
      }
      if (shouldFetchChat) {
        try {
          await Promise.all([
            this.getVisitorInfoOfChat(chatId),
            this.getChatLog(chatId),
          ]);
        } catch (error) {
          this.$log.error(error);
        } finally {
          this.loadingChat[chatId] = false;
        }
      }
      row.toggleDetails();
    },
    async getVisitorInfoOfChat(chatId) {
      if ((!Object.prototype.hasOwnProperty.call(this.chats, chatId))
        || !this.chats[chatId].visitorInfo) {
        const visitorInfo = await getVisitorInfoOfChat(chatId, true);
        this.$set(this.chats[chatId], 'visitorInfo', visitorInfo);
      }
    },
    async getChatLog(chatId) {
      if (!(chatId in this.chats) || (!this.chats[chatId].chatlogs)) {
        let chatlogs = [];
        try {
          const chatlog = await getChatLog(chatId);

          // get urls for visitor file uploads
          if (Array.isArray(chatlog)) {
            chatlogs = await Promise.all(chatlog.map(async (msg) => {
              if (msg.command === chatEvent.VISITOR_FILE_UPLOAD) {
                const newMsg = msg;
                let pathToFile = '';
                try {
                  const { url } = await getVisitorFileUpload(msg.uuid);
                  pathToFile = url;
                } catch (error) {
                  pathToFile = this.$t('vocabulary.deleted');
                } finally {
                  newMsg.path = pathToFile;
                }
                return newMsg;
              }
              return msg;
            }));
          }
        } catch (error) {
          this.$store.commit('errorDisplay/ADD_MSG', {
            message: this.$t('errors.chatlogError'),
          });
        } finally {
          this.$set(this.chats[chatId], 'chatlogs', chatlogs);
        }
      }
    },
    isDeleteAllowed(item) {
      return !Object.prototype.hasOwnProperty.call(this.selectedChats, item.chat_id)
        || this.selectedChats[item.chat_id] === null;
    },
    removeChatsFromSelectedChats(toRemoveChats) {
      for (const id of toRemoveChats) {
        delete this.selectedChats[id];
      }
    },
    async deleteSelectedChat(type = '') {
      let toRemoveChatIds = [];
      if (type === 'all') {
        toRemoveChatIds = Object.keys(this.selectedChats);
      } else {
        for (const [key, value] of Object.entries(this.selectedChats)) {
          if (value) toRemoveChatIds.push(key);
        }
      }
      const data = { chat_ids: toRemoveChatIds };
      try {
        const resp = await deleteChat(data);
        if (resp.status === 204) {
          this.removeChatsFromSelectedChats(toRemoveChatIds);
          this.getChats({ keepChats: false });
          this.$bvToast.toast(this.$t('message.archiveToastBody'), {
            title: this.$t('message.archiveToastTitle'),
            autoHideDelay: 5000,
            variant: 'success',
          });
        }
      } catch (error) {
        this.$log.error(error);
      }
    },
    async prepareAndSetRatingOptions() {
      const tenantIds = this.tenants.map((t) => t.id);
      const tag = 'general';
      try {
        /*
          check if we have already gotten the general configs for all available tenants
          if not, we get them now so we can extract rating settings
        */
        await Promise.all(tenantIds.map((tenantId) => (!('chat_rating' in this.getConfig({ tenantId, tag }))
          ? this.fetchAndSetConfig({ tenantId, tag })
          : undefined)));

        // loop over general config for every tenant
        tenantIds.forEach((tenantId) => {
          const { chat_rating: chatRatingConfig } = this.getConfig({ tenantId, tag });

          /*
            set rating options if rating is enabled and
            (agents are allowed to search for ratings or current user is a manager)
          */
          if (chatRatingConfig.enable
            && (chatRatingConfig.general_agent_ratings_visible_in_archive === true
              || this.maxRole >= MANAGER_ROLE)
          ) {
            const ratingDefaults = [
              { value: -1.0, text: this.$t('message.notRated') },
              { value: null, text: this.$t('message.allRated') },
            ];
            for (let i = 1; i <= chatRatingConfig.scale; i++) {
              ratingDefaults.push({
                value: (100 / (chatRatingConfig.scale - 1)) * (i - 1), text: this.$tc('vocabulary.star', i, { number: i }),
              });
            }
            this.ratingOptions = Object.assign(this.ratingOptions, { [tenantId]: ratingDefaults });
          }
        });
      } catch (error) {
        this.$log.error(error);
      }
    },
    prepareParams() {
      let intervalStart;
      let intervalEnd;
      const timeSlot = this.timeSlot.filter((date) => date !== null);
      if (timeSlot.length === 2) {
        [intervalStart, intervalEnd] = timeSlot.map((time) => moment(time).unix());
      } else {
        intervalStart = 0;
        intervalEnd = 0;
      }

      const params = {
        agent: this.agentSelectedArray,
        visitor: this.visitorSearch,
        content: this.contentSearch,
        interval_start: intervalStart,
        interval_end: intervalEnd,
        tenants: this.tenantSelectedArray,
        tags: this.tagSelectedArray,
      };
      if (this.departmentSelectedArray.length) {
        params.department = this.departmentSelectedArray.map((depId) => {
          const match = this.departments.find((dep) => dep.id === depId);
          if (match) return match.name;
          return depId;
        });
      }

      if (this.ratingSearch !== null && this.tenantSelectedArray[0] in this.ratingSearch
        && this.ratingSearch[this.tenantSelectedArray[0]] !== null) {
        params.rating = this.ratingSearch[this.tenantSelectedArray[0]];
      }
      return params;
    },
    openArchiveSinglePage(chatId) {
      const path = this.$router.resolve({ name: 'archive-single', params: { chatId } });
      window.open(path.href, chatId);
    },
    prettyMetaString(chatId) {
      let s = `${this.$t('message.visitorMetaString')}\n`;
      s += metaDataObjectToString(this, this.chats[chatId].visitorInfo);
      s += '\n';
      s += msgsToString(this, this.chats[chatId].chatlogs, this.agents);
      return s;
    },
    copyChat(chatId) {
      copy(this.prettyMetaString(chatId));
    },
  },
};
</script>

<style>
.archive-page .page-link {
  border: none !important;
  border-radius: 0 !important;
}

.date-picker {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
</style>
