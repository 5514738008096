<template>
  <div>
    <HiddenDepartmentsSelector blob-key="hiddenDepartmentsIncoming" />
    <b-row
      no-gutters
      class="mb-3"
    >
      <b-col
        lg="2"
        class="pr-lg-2 pb-2 pb-lg-0"
      >
        <floating-container
          class="d-flex justify-content-center align-items-center h-100"
          no-body
        >
          {{ `${items.length} ${$t('message.chatsTotal').toLowerCase()}` }}
        </floating-container>
      </b-col>
      <b-col
        lg="3"
        class="pr-lg-2 pb-2 pb-lg-0"
      >
        <floating-container
          class="d-flex justify-content-center align-items-center h-100"
          no-body
        >
          {{ `${$t('message.longestWaitTime')} ${longestQueueTime}` }}
        </floating-container>
      </b-col>
      <b-col>
        <floating-container
          class="d-flex justify-content-center h-100 px-2"
          no-body
        >
          <b-progress
            height="2.25rem"
            :max="items.length"
          >
            <b-progress-bar
              v-show="kpiBreachChatIDs.length"
              variant="danger"
              :value="kpiBreachChatIDs.length"
            >
              {{ `${kpiBreachChatIDs.length} ${$t('message.chatsOverKPI')}` }}
            </b-progress-bar>
            <b-progress-bar
              v-show="kpiApproachChatIDs.length"
              variant="warning"
              :value="kpiApproachChatIDs.length"
            >
              {{ `${kpiApproachChatIDs.length} ${$t('message.chatsNearingKPI')}` }}
            </b-progress-bar>
            <b-progress-bar
              variant="success"
              :value="items.length - kpiApproachChatIDs.length - kpiBreachChatIDs.length"
            >
              {{ `${items.length - kpiApproachChatIDs.length - kpiBreachChatIDs.length}
              ${$tc('message.chatsIncoming', 2)}` }}
            </b-progress-bar>
            <b-progress-bar
              v-show="items.length === 0"
              variant="primary"
              :value="100"
            >
              {{ $tc('message.chatsIncoming', 1) }}
            </b-progress-bar>
          </b-progress>
        </floating-container>
      </b-col>
    </b-row>
    <floating-container
      v-if="!!personal_chats.length"
      body-class="p-2"
      :title="$t('message.chatsPersonal')"
    >
      <b-table
        tbody-tr-class="text-break cursor-pointer"
        no-sort-reset
        small
        bordered
        stacked="sm"
        :items="personal_chats"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="mb-0"
        @row-clicked="rowClickHandlerIncoming"
      >
        <template #cell(queueStartTime)="data">
          <QueueTimeDisplay
            :start-time="data.value"
            :chat-id="data.item.id"
          />
        </template>
        <template #cell(visitor_name)="data">
          <span
            v-b-popover.hover.right="data.value"
          >
            {{ trimLength(data.value) }}
          </span>
        </template>
        <template #cell(msgs)="{ item, value: { preview } }">
          <ChatLanguageBadge v-bind="{ chat: item }" />
          <span
            :id="item.id"
            @mouseenter="hoveredChatId = item.id"
            @mouseleave="hoveredChatId = ''"
          >
            {{ /\S/.test(preview) ? preview : $t('message.noVisitorMessages') }}
          </span>
        </template>
      </b-table>
    </floating-container>
    <floating-container
      v-if="!!general_chats.length"
      body-class="p-2"
      :title="$t('message.chatsGeneral')"
    >
      <b-table
        tbody-tr-class="cursor-pointer text-break"
        no-sort-reset
        small
        bordered
        stacked="sm"
        :items="general_chats"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="mb-0"
        @row-clicked="rowClickHandlerIncoming"
      >
        <template #cell(queueStartTime)="data">
          <QueueTimeDisplay
            :start-time="data.value"
            :chat-id="data.item.id"
          />
        </template>
        <template #cell(visitor_name)="data">
          <span
            v-b-popover.hover.right="data.value"
          >
            {{ trimLength(`${data.value}`) }}
          </span>
        </template>
        <template #cell(msgs)="{ item, value: { preview } }">
          <ChatLanguageBadge v-bind="{ chat: item }" />
          <span
            :id="item.id"
            @mouseenter="hoveredChatId = item.id"
            @mouseleave="hoveredChatId = ''"
          >
            {{ /\S/.test(preview) ? preview : $t('message.noVisitorMessages') }}
          </span>
        </template>
      </b-table>
    </floating-container>
    <floating-container
      v-else
      no-body
    >
      <h4 class="py-5 text-center text-muted">
        {{ $t('message.chatsNoIncFound') }}
      </h4>
    </floating-container>
    <hover-chat-preview
      v-if="hoveredChatId"
      :target="hoveredChatId"
      :chat="items.find((chat) => chat.id === hoveredChatId)"
      :show="!!hoveredChatId"
      @mouseenter="hoveredChatId = hoveredChatId"
      @mouseleave="hoveredChatId = ''"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import {
  extractTransferInfo,
  extractVisitorName,
  previewVisitorMessages,
  trimLength,
} from 'supwiz/supchat/generalUtils';
import { secondsToHHMMSS } from '@/utils/analytics';

import ChatLanguageBadge from '@/components/ChatLanguageBadge.vue';
import HoverChatPreview from '@/components/HoverChatPreview.vue';
import HiddenDepartmentsSelector from '@/components/HiddenDepartmentsSelector.vue';
import QueueTimeDisplay from '@/pages/IncomingChatsPage/QueueTimeDisplay.vue';

import { pickupChat } from '@/api/apiList';
import { useCurrentTime } from '@/composables/currentTime';

export default {
  name: 'IncomingChatsPage',
  components: {
    ChatLanguageBadge,
    HiddenDepartmentsSelector,
    HoverChatPreview,
    QueueTimeDisplay,
  },
  setup() {
    const currentTime = useCurrentTime('IncomingChatsPage');
    return { currentTime };
  },
  data() {
    return {
      now: null,
      hoveredChatId: '',
      sortBy: 'queueStartTime',
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters('agent', ['id', 'departmentNameIncludeTenantName']),
    ...mapGetters('chat', [
      'visibleIncomingChats',
      'kpiApproachChatIDs',
      'kpiBreachChatIDs',
      'getChatCurrentQueueStartTime',
      'getChatKPITarget',
    ]),
    ...mapGetters('systemAgents', ['systemAgents']),
    ...mapGetters('status', ['myStatus']),
    ...mapGetters('tenants', ['getConfig']),
    fields() {
      return [
        {
          key: 'queueStartTime',
          label: this.$t('message.waitTime'),
          thStyle: {
            width: '12%',
            minWidth: '80px',
          },
          sortable: true,
        },
        {
          key: 'kpi',
          label: this.$t('vocabulary.kpi'),
          thStyle: {
            width: '10%',
            minWidth: '80px',
          },
          sortable: true,
          formatter: this.getChatKPIQueueTarget,
        },
        {
          key: 'department_id',
          label: `${this.$tc('vocabulary.tenant', 1)}: ${this.$tc('vocabulary.department', 1)}`,
          thStyle: {
            width: '15%',
            minWidth: '100px',
          },
          sortable: true,
          formatter: this.formatDepartments,
        },
        {
          key: 'transferedFrom',
          label: this.$t('message.transferedFrom'),
          thStyle: {
            width: '15%',
            minWidth: '100px',
          },
          sortable: true,
          formatter: this.getTransferedFrom,
        },
        {
          key: 'visitor_name',
          label: this.$t('message.visitorName'),
          thStyle: {
            width: '15%',
            minWidth: '100px',
          },
          sortable: false,
          formatter: this.getVisitorName,
        },
        {
          key: 'msgs',
          label: this.$t('message.previewOfVisitorMessages'),
          sortable: false,
          formatter: this.previewFormatter,
        }];
    },
    items() {
      const chats = this.visibleIncomingChats;
      return chats.map((chat) => {
        const thisChat = { ...chat };
        const id = thisChat.id;
        thisChat.queueStartTime = this.getChatCurrentQueueStartTime(id);
        if (this.kpiApproachChatIDs.includes(id)) {
          thisChat._cellVariants = { queueStartTime: 'warning' };
        } else if (this.kpiBreachChatIDs.includes(id)) {
          thisChat._cellVariants = { queueStartTime: 'danger' };
        }
        return thisChat;
      });
    },
    personal_chats() {
      function isPersonalChat(chat) {
        const transferInfo = extractTransferInfo(chat);
        return transferInfo[0] !== '' && transferInfo[1] === 'agent';
      }
      const personalChats = this.items.filter(
        isPersonalChat,
      );
      return personalChats;
    },
    general_chats() {
      const generalChats = this.items.filter(
        (x) => !this.personal_chats.includes(x),
      );
      return generalChats;
    },
    longestQueueTime() {
      if (!this.items.length) return secondsToHHMMSS(0);
      const firstQueueTime = Math.min(...this.items.map(({ queueStartTime }) => queueStartTime));
      return secondsToHHMMSS((this.currentTime - firstQueueTime) / 1000);
    },
    displayDepartmentSelector() {
      return this.departmentNameIncludeTenantName.length > 1;
    },
  },
  created() {
    this.ensureAgentsFetched();
    this.ensureControlSocketSet();
    // force incoming chats update, bypassing time check
    this.$store.dispatch('chat/refreshAllIncomingChats', { bypassTimeCheck: true });
  },
  methods: {
    ...mapActions('systemAgents', ['ensureAgentsFetched']),
    ...mapActions('controlSocket', ['ensureControlSocketSet']),
    previewVisitorMessages,
    agentIdToName(id) {
      if (!id) return '-';
      const agentMatch = this.systemAgents.find((agent) => agent.id === Number(id));
      return agentMatch ? agentMatch.display_name : id;
    },
    formatDepartments(chatDep) {
      const result = this.departmentNameIncludeTenantName
        .find((department) => department.id === chatDep);
      if (!result) {
        return chatDep;
      }
      return result.name;
    },
    async rowClickHandlerIncoming(clickedChat) {
      if (this.requireOnlineStatus(clickedChat.tenant)) {
        this.$store.commit('errorDisplay/ADD_MSG', {
          message: this.$t('message.chatPickupRequiresOnlineStatus'),
          variant: 'warning',
        });
        return;
      }

      // If an agent is found, then the chat is already picked up
      if (Object.keys(clickedChat.active_agents).filter((x) => x !== '').length !== 0) {
        this.$store.commit('errorDisplay/ADD_MSG', {
          message: this.$t('message.chatAlreadyTaken'),
          variant: 'warning',
        });
        return;
      }
      // Else we try to pick up the chat.
      try {
        const data = { chat_id: clickedChat.id };
        await pickupChat(data);
        this.$store.commit(
          'controlSocket/UPDATE_REFRESH_STATUS',
          { key: 'chatModal', value: true },
        );
      } catch (error) {
        this.$store.commit('errorDisplay/ADD_MSG', {
          message: this.$t('message.chatPickupError'),
        });
        this.$log.error(error);
      }
    },
    getChatKPIQueueTarget(val, columnId, chat) {
      return secondsToHHMMSS(this.getChatKPITarget(chat.id));
    },
    getTransferedFrom(val, columnId, chat) {
      return this.agentIdToName(extractTransferInfo(chat)[0]);
    },
    getVisitorName(val, columnId, chat) {
      return extractVisitorName(chat);
    },
    requireOnlineStatus(tenantId) {
      const agentOnline = this.myStatus === 'ON';
      const requireOnline = this.getConfig({ tenantId, tag: 'general' })?.pickup_chat_requires_online_status;
      return requireOnline && !agentOnline;
    },
    previewFormatter(msgs) {
      if (!Array.isArray(msgs)) return { preview: '' };
      return { preview: trimLength(previewVisitorMessages(msgs), 96) };
    },
    trimLength,
  },
};
</script>
