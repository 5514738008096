var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._l((_vm.textParts),function(part,i){return [(part.type === 'list' && part.items.length)?_c('MsgPartList',_vm._b({key:i},'MsgPartList',{ part, msgRole: _vm.msgRole },false)):(part.type === 'option')?_c('UserMsgOption',{key:i,attrs:{"option":part,"user-role":_vm.userRole,"disabled":_vm.chatIsEnded
      || _vm.userRole !== 'visitor'
      || _vm.visitorHasReplied && !part.keepOnReply,"component-id":_vm.msg.uuid + i}}):_c('MsgPart',_vm._b({key:i},'MsgPart',{ part },false))]}),(!!_vm.translationObj)?_c('div',[_c('span',{staticClass:"d-flex justify-content-between w-100"},[_c('a',{staticClass:"cursor-pointer",on:{"click":_vm.toggleShowTranslated,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.toggleShowTranslated.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.toggleTranslationBtnText))]),(!_vm.hasMarkedAsWrongTranslation)?_c('a',{staticClass:"cursor-pointer ml-2 report-translation",style:(_vm.markTranslationStyles),on:{"click":function($event){return _vm.markAsWrongTranslation({ action: 'add' })},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.markAsWrongTranslation({ action: 'add' })}}},[_vm._v(_vm._s(_vm.$t('status.markAsWrongTranslation')))]):(_vm.hasMarkedAsWrongTranslation)?_c('a',{staticClass:"cursor-pointer ml-2 report-translation",style:(_vm.markTranslationStyles),on:{"click":function($event){return _vm.markAsWrongTranslation({ action: 'remove' })},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.markAsWrongTranslation({ action: 'remove' })}}},[_vm._v(_vm._s(_vm.$t('status.hasBeenMarkedAsWrongTranslation')))]):_vm._e()])]):(_vm.userRole === 'agent'
      && !_vm.isNote
      && _vm.needsTranslation
      && !_vm.translationObj
      && _vm.translationsEnabled)?_c('div',[(!_vm.translateNowDisabled && _vm.ack && !_vm.chatIsEnded)?_c('a',{staticClass:"cursor-pointer",on:{"click":_vm.emitTranslate,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.emitTranslate.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('status.translateNow', { language: _vm.targetTranslationLanguage }))+" ")]):_vm._e()]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }