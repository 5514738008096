<template>
  <b-modal
    ref="modal"
    body-class="overflow-hidden position-relative"
    centered
    content-class="h-100"
    dialog-class="chat-modal-dialog"
    footer-class="border-top-0 pt-0"
    no-close-on-esc
    no-fade
    no-stacking
    @shown="onShown"
    @hidden="onHidden"
  >
    <template #modal-header>
      <div class="d-flex w-100 align-items-center">
        <h4
          class="mb-0 mr-auto"
          v-text="$t('message.chatModal.modalTitle', {
            department: department ? department.name : $tc('vocabulary.department', 1),
            tenant: department ? department.tenant_name : $tc('vocabulary.tenant', 1),
            visitor: chat.display_name || $t('vocabulary.visitor'),
          })"
        />
        <b-button
          class="text-muted"
          variant="link"
          :title="$t('message.chatModal.minimizeChatModal')"
          @click="$refs.modal.hide()"
        >
          <span class="sr-only">{{ $t('message.chatModal.minimizeChatModal') }}</span>
          <font-awesome-icon icon="window-minimize" />
        </b-button>
        <FadeIn>
          <b-button
            v-if="isEnded"
            id="close-chat-window-button"
            class="text-muted ml-2"
            variant="link"
            :title="$t('message.chatModal.removeChatModal')"
            @click="closeChatWindow"
          >
            <span class="sr-only">{{ $t('message.chatModal.removeChatModal') }}</span>
            <font-awesome-icon icon="times" size="xl" />
          </b-button>
        </FadeIn>
      </div>
    </template>
    <b-row class="overflow-hidden h-100" no-gutters>
      <b-col class="overflow-hidden h-100">
        <ChatComponent
          ref="chatHistory"
          class="overflow-auto h-100"
          v-bind="{
            preparedHistory,
            translations,
            tags,
            sendersInfo,
            activeUsers,
            typingSender,
            userRole,
            styles,
            addOffset: !!cannedMessages.length,
          }"
          @resend-message="resendMsg"
          @translate="translateMsg"
          @mark-wrong-translation="markMsgTranslationAsWrong"
        >
          <template #file-confirmation="fileConfirmation">
            <VisitorFileConfirmation
              v-bind="{
                msg: fileConfirmation.msg,
                userName: fileConfirmation.userName,
                userRole,
                chatId,
              }"
            />
          </template>
          <template #message-aside="{ msg }">
            <template v-if="articlePredictionEnabled && msg.sender_role === 'visitor' && msg.command === $options.chatEvent.SAY">
              <b-button
                v-if="agentLanguage === visitorLanguage"
                v-b-tooltip="$options.tooltipOptions"
                size="sm"
                variant="link"
                :title="$t('message.chatModal.articleSearch')"
                @click="setVisitorMsgAsArticleSearch({ msg, useOriginal: true })"
              >
                <span class="sr-only">{{ $t('message.chatModal.articleSearch') }}</span>
                <font-awesome-icon class="text-muted" icon="book" />
              </b-button>
              <b-dropdown
                v-else
                v-b-tooltip="$options.tooltipOptions"
                no-caret
                size="sm"
                variant="link"
                menu-class="shadow-sm"
                :title="$t('message.chatModal.articleSearch')"
              >
                <template #button-content>
                  <span class="sr-only">{{ $t('message.chatModal.articleSearch') }}</span>
                  <font-awesome-icon class="text-muted" icon="book" />
                </template>
                <b-dropdown-item
                  @click="setVisitorMsgAsArticleSearch({ msg })"
                >
                  {{ $t('message.chatModal.articleSearchBookDropdown.translatedLanguage') }}
                </b-dropdown-item>
                <b-dropdown-item
                  @click="setVisitorMsgAsArticleSearch({ msg, useOriginal: true })"
                >
                  {{ $t('message.chatModal.articleSearchBookDropdown.originalLanguage') }}
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </template>
        </ChatComponent>
        <portal-target
          name="option-description"
          multiple
        />
      </b-col>
      <b-col cols="3" class="overflow-hidden h-100 pl-2" style="min-width:200px">
        <b-tabs
          ref="tabs"
          v-model="tabIndex"
          justified
          content-class="mt-3 overflow-x-hidden overflow-y-auto position-relative
            flex-fill scrollbar-slim"
          class="h-100 d-flex flex-column"
          small
        >
          <b-tab
            :title="$t('vocabulary.meta')"
          >
            <div class="d-flex align-items-end">
              <HandlingTime
                v-bind="{ chatId }"
                class="flex-fill"
              />
            </div>
            <component
              :is="hasTechnicalData ? 'b-tabs' : 'div'"
              justified
              content-class="mt-2 overflow-auto"
              class="mt-4 h-100 d-flex flex-column"
              small
            >
              <component
                :is="hasTechnicalData ? 'b-tab' : 'div'"
                :title="$t('message.chatModal.visitorDetails')"
              >
                <visitor-meta-data
                  :chat-id="chatId"
                  :tenant-id="tenantId"
                  :config="generalConfig"
                  @updateMetaData="updateMetaData"
                  @updateCustomMetaData="updateCustomMetaData"
                  @ready="handleVisitorMetaReady"
                />
              </component>
              <b-tab
                v-if="hasTechnicalData"
                :title="$t('message.chatModal.technicalDetails')"
              >
                <visitor-technical-data
                  :meta-data="metaData"
                />
              </b-tab>
            </component>
          </b-tab>
          <b-tab
            v-if="cannedMessages.length && !isEnded"
            :title="$tc('vocabulary.response', 2)"
          >
            <div
              class="d-flex flex-column h-100"
            >
              <label class="mb-0">
                {{ $t('message.pageCanned') }}
              </label>
              <div class="cannedlist bg-light border flex-grow-1">
                <button
                  v-for="(msg, i) in cannedMessages"
                  :key="i"
                  class="list-group-item-action"
                  :title="msg.content"
                  type="button"
                  @click="processCannedMsg(msg.content)"
                >
                  {{ msg.subject }}
                </button>
              </div>
            </div>
          </b-tab>
          <b-tab
            v-if="Object.keys(integrationObjects).length"
            :title="$tc('vocabulary.integration', 2)"
          >
            <div class="border-bottom" />
            <div
              v-for="integration in integrationObjects"
              :key="`integration-${integration.id}-${chatId}`"
              class="p-0 border border-top-0"
            >
              <b-button
                v-b-toggle="`integration-${integration.id}-${chatId}`"
                block
                class="text-left border-0 py-2"
                style="box-shadow:none"
              >
                {{ integration.name }}
              </b-button>
              <b-collapse
                :id="`integration-${integration.id}-${chatId}`"
                :accordion="`accordion-integration-${chatId}`"
                class="border-top"
              >
                <chat-integration
                  v-bind="{
                    integration,
                    metaDataForVariables,
                  }"
                />
              </b-collapse>
            </div>
          </b-tab>
          <b-tab
            v-if="displayAITab"
            :title="$t('message.chatModal.predictions.title')"
          >
            <chat-predictions
              ref="ChatPredictions"
              v-bind="{
                chatId,
                departmentId,
                tenantId,
              }"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <template #modal-footer>
      <b-row class="w-100" no-gutters>
        <b-col cols="10">
          <MessageTextarea
            ref="MessageTextarea"
            v-bind="{
              agentLanguage,
              chatId,
              departmentId,
              tenantId,
              visitorLanguage,
              autoTranslationEnabled,
              metaDataForVariables,
            }"
            @answered-chat="$emit('answered-chat', chatId)"
            @input="typingOn"
            @set-language="setLanguage"
            @toggle-translation="toggleAutoTranslation"
          >
            <template #actions-left>
              <b-button
                v-if="!isEnded && hasSentStartMsg"
                id="end-chat-button"
                class="border-right border-right-radius-0"
                size="sm"
                variant="link"
                :title="$t('message.chatModal.chatEnd')"
                @click="showEndChatPopover = !showEndChatPopover"
              >
                <span class="sr-only">{{ $t('message.chatModal.chatEnd') }}</span>
                <font-awesome-icon icon="times" size="lg" />
              </b-button>
              <chat-transfer
                v-if="hasSentStartMsg && !isEnded"
                class="border-right border-right-radius-0"
                :chat-socket="chatSocket"
                :chat-id="chatId"
                :transfer-enabled="transferEnabled"
                :chat-ended="isEnded"
                :is-active="hasSentStartMsg"
                :tenant-id="tenantId"
                :department-id="departmentId"
                @transferred="removeChat"
              />
              <CopyChatOptions
                v-bind="{
                  chatLogs: history,
                  metadata: { ...metaData, ...customMetaData },
                  language: agentLanguage,
                }"
                class="border-right border-right-radius-0"
                dropup
                no-caret
                size="sm"
                variant="link"
                :title="$t('vocabulary.copy')"
              >
                <span class="sr-only">{{ $t('vocabulary.copy') }}</span>
                <font-awesome-icon icon="copy" />
              </CopyChatOptions>
              <b-button
                v-if="showRemoteSessionBtn"
                class="border-right border-right-radius-0"
                size="sm"
                variant="link"
                @click="sendExternalURILink"
              >
                {{ $t('message.externalURI.sendSessionLink') }}
              </b-button>
            </template>
          </MessageTextarea>
        </b-col>
        <b-col
          cols="2"
          class="pl-2 d-flex flex-column"
        >
          <b-button
            v-if="hasSentStartMsg"
            block
            size="lg"
            :disabled="!socketReady || isEnded"
            @click="() => $refs.MessageTextarea.sendMessageWrapper()"
          >
            {{ $t('vocabulary.send') }}
          </b-button>
          <b-button
            v-else-if="!isEnded"
            block
            size="lg"
            :disabled="isEnded"
            @click="sendStartMessage"
          >
            {{ $t('message.chatModal.chatBegin') }}
          </b-button>
          <b-button
            v-else
            block
            disabled
            variant="link"
          >
            {{ $t('vocabulary.ended') }}
          </b-button>
          <b-form-group v-if="!isEnded">
            <b-form-checkbox
              v-model="disableSendWithEnter"
              class="d-inline-block"
            />
            <small>{{ $t('message.chatModal.disableEnter') }}</small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-popover
        v-if="isEnded && !visitorMetaDataReady"
        target="close-chat-window-button"
        triggers=""
        placement="righttop"
        :show.sync="showCloseChatWindowPopover"
      >
        <p class="text-danger">
          {{ $t('message.chatModal.visitorMetaDataNotReady') }}
        </p>
      </b-popover>
      <b-popover
        v-if="!isEnded && hasSentStartMsg"
        target="end-chat-button"
        triggers=""
        placement="righttop"
        :show.sync="showEndChatPopover"
      >
        <p>
          {{ $t('message.chatModal.chatEndConfirm') }}
          <strong
            v-if="activeUsers.size > 1"
            class="mt-1"
          >
            {{ $t('message.chatModal.chatEndWarn') }}
            {{ $t('message.chatModal.chatEndWarn2') }}
          </strong>
        </p>
        <div
          v-if="visitorMetaDataReady === false"
          class="text-danger small mb-1"
        >
          {{ $t('message.chatModal.visitorMetaDataNotReady') }}
        </div>
        <div class="d-flex justify-content-between">
          <b-button
            size="sm"
            variant="danger"
            :disabled="visitorMetaDataReady === false"
            @click="endChatSession"
          >
            {{ $t('message.chatModal.chatEnd') }}
          </b-button>
          <b-button
            size="sm"
            variant="secondary"
            @click="showEndChatPopover = false"
          >
            {{ $t('vocabulary.cancel') }}
          </b-button>
        </div>
      </b-popover>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import typingIndicatorMixin from 'supwiz/supchat/mixins/typingIndicatorMixin';
import historyParsingMixin from 'supwiz/supchat/mixins/historyParsingMixin';
import ChatComponent from 'supwiz/supchat/components/chat/ChatComponent.vue';
import {
  getSenderName,
  sendCommand,
  sendMessage,
  notify,
  metaDataObjectToString,
  msgsToString,
} from 'supwiz/supchat/generalUtils';

import { chatEvent } from 'supwiz/supchat/constants';
import {
  sendCustomVisitorInfo,
  getHistory,
  endChatSession,
  setChatTranslationLanguage,
} from '@/api/apiList';
import { tooltipOptions } from '@/utils/constants';
import FadeIn from '@/components/Transitions/FadeIn.vue';
import ChatTransfer from '@/components/chatModal/ChatTransfer.vue';
import VisitorMetaData from '@/components/chatModal/VisitorMetaData.vue';
import MessageTextarea from './Textarea/MessageTextarea.vue';

export default {
  name: 'ChatModal',
  components: {
    ChatIntegration: () => import('@/components/chatModal/ChatIntegration.vue'),
    CopyChatOptions: () => import('@/components/CopyChatOptions.vue'),
    HandlingTime: () => import('./HandlingTime.vue'),
    VisitorMetaData,
    VisitorTechnicalData: () => import('@/components/chatModal/VisitorTechnicalData.vue'),
    ChatTransfer,
    ChatComponent,
    FadeIn,
    MessageTextarea,
    ChatPredictions: () => import('@/components/chatModal/ChatPredictions.vue'),
    VisitorFileConfirmation: () => import('./VisitorFileConfirmation.vue'),
  },
  mixins: [typingIndicatorMixin, historyParsingMixin],
  tooltipOptions,
  chatEvent,
  provide() {
    return {
      addSummaryAsNote: this.addSummaryAsNote,
      agentLanguage: () => this.agentLanguage,
      agentId: () => this.info.id,
      visitorLanguage: () => this.visitorLanguage,
      translationsEnabled: () => this.translationsEnabled,
      focusInputField: this.focusInputField,
      messageTextareaRef: () => this.$refs.MessageTextarea,
      inputRefFunc: () => this.$refs.MessageTextarea.$refs.inputField,
    };
  },
  props: {
    chat: {
      type: Object,
      required: true,
    },
    autoOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      agentId: null,
      agentLanguage: 'da',
      autoTranslationEnabled: false,
      visitorLanguage: 'da',
      userRole: 'agent',
      chatId: null,
      disableSendWithEnter: false,
      sendersInfo: { visitor: {}, agent: {} },
      msgsSeen: 0,
      monitorTypingSenderRole: 'visitor', // used in typingIndicatorMixin
      metaData: {},
      customMetaData: {},
      visitorMetaDataReady: null,
      showEndChatPopover: false,
      showCloseChatWindowPopover: false,
    };
  },
  computed: {
    ...mapState(['featureFlags']),
    ...mapState('chat', ['chatSocket']),
    ...mapState('agent', ['info']),
    ...mapState('agent/settings', ['agentSettings']),
    ...mapGetters('chat/chatModals', ['getModalValue']),
    ...mapGetters('agent', ['departments']),
    ...mapGetters('systemAgents', { agents: 'systemAgents' }),
    ...mapGetters('tenants', ['getConfig']),
    ...mapState('tenants/languages', ['tenantTranslations']),
    ...mapGetters('tenants/languages', ['getDefaultLanguage', 'getAgentLanguages']),
    ...mapGetters('tenants/predictions', ['enabledPredictions']),
    ...mapGetters('integrations', ['integrationObjectsByTenant']),
    tenantId() { return this.chat.tenant_id; },
    departmentId() { return this.chat.department_id; },
    department() {
      return this.departments.find((dep) => dep.id === this.departmentId);
    },
    tenantCannedMessages() {
      return this.$store.getters['tenants/getTenantCannedMsg'](this.tenantId);
    },
    departmentCannedMessages() {
      return this.$store.getters['departments/getDepartmentCannedMsgs'](this.departmentId);
    },
    cannedMessages() {
      return this.tenantCannedMessages.concat(this.departmentCannedMessages);
    },
    tabIndex: {
      get() {
        return this.getModalValue({ chatId: this.chatId, field: 'tabIndex' });
      },
      set(value) {
        this.setTabIndex({ chatId: this.chatId, value });
      },
    },
    integrationObjects() {
      if (!this.tenantId) {
        return [];
      }
      const integrations = this.integrationObjectsByTenant(this.tenantId);
      return integrations.filter((a) => a.active);
    },
    generalConfig() { return this.getConfig({ tenantId: this.tenantId, tag: 'general' }); },
    autoStartEnabled() { return this.generalConfig ? this.generalConfig.auto_start_chat : false; },
    autoCloseEnabled() { return this.generalConfig ? this.generalConfig.auto_close_chat : false; },
    transferEnabled() {
      return this.generalConfig ? this.generalConfig
        .transfer_chat_enabled : false;
    },
    translationsEnabled() {
      if (!this.featureFlags.TRANSLATION_ENABLED) return false;
      return this.tenantTranslations?.[this.tenantId]?.translation_enabled;
    },
    rawHistory() { return this.$store.getters['chat/getChatHistory'](this.chatId); },
    unAckMsgs() { return this.$store.getters['chat/getUnAckMsgs'](this.chatId); },
    isEnded() { return this.$store.getters['chat/hasChatBeenClosed'](this.chatId); },
    hasSentStartMsg() { return this.$store.getters['chat/isChatCurrentlyStarted'](this.chatId); },
    msgsUnseen() { return this.historySay.length - this.msgsSeen; },
    history() {
      const data = [];
      const senders = { visitor: [], agent: [] };

      for (let i = 0; i < this.rawHistory.length; i++) {
        const msg = { ...this.rawHistory[i] };

        if (msg.sender_role !== 'system' && !senders[msg.sender_role].includes(msg.sender_id)) {
          if (!Object.prototype.hasOwnProperty.call(
            this.sendersInfo[msg.sender_role], msg.sender_id,
          )) {
            this.setSenderName(msg.sender_id, msg.sender_role);
          }
          senders[msg.sender_role].push(msg.sender_id);
        }

        data.push(msg);
      }
      return data;
    },
    showing() {
      return this.$store.getters['chat/chatModals/isModalVisible'](this.chatId);
    },
    notificationFlag() {
      return this.$store.getters['chat/getNotificationFlag'](this.chatId);
    },
    socketReady: {
      cache: false,
      get() {
        return this.chatSocket !== null && this.chatSocket.readyState === 1;
      },
    },
    hasTechnicalData() {
      return Object.keys(this.metaData)
        .some((key) => ['device', 'site_location'].includes(key));
    },
    enabledPredictionsObj() {
      return this.enabledPredictions({ tenantId: this.tenantId });
    },
    displayAITab() {
      if (this.isEnded) return false;
      const predictionsObj = this.enabledPredictionsObj;
      return [
        predictionsObj.summary,
        predictionsObj.sentiment,
        predictionsObj.article,
      ].includes(true);
    },
    articlePredictionEnabled() {
      return this.enabledPredictionsObj.article;
    },
    showRemoteSessionBtn() {
      // intended for BeyondTrust remote session links
      // modal will mention BeyondTrust, so enable with care
      if (!this.featureFlags.SHOW_EXTERNAL_URI_BTN || this.isEnded) return false;
      return ![undefined, null, 'error'].includes(this.info.externalUri);
    },
    styles() {
      return {
        borderRadius: false,
        chatColors: ['rgb(236, 236, 236)', 'rgb(210, 228, 243)'],
        fontColor: '#000',
        fontSize: '14px',
      };
    },
  },
  watch: {
    hasSentStartMsg(msgSent) {
      if (msgSent && this.showing) {
        this.focusInputField();
      }
    },
    notificationFlag() {
      // new msg notification does not have a setting currently
      this.extractToNotifyMsg(this.chatId).then((msg) => {
        if (msg) {
          const senderName = getSenderName(
            this.sendersInfo, msg.sender_id, msg.sender_role,
          );
          const notificationHead = this.$t('userSettings.notifications.new_message', {
            user: senderName,
          });
          const notification = notify(notificationHead, { body: msg.text });
          if (notification) {
            notification.onclick = () => {
              window.focus();
              if (this.$refs.modal) this.$refs.modal.show();
            };
          }
        }
      });
    },
    historySay(newVal) {
      if (this.showing) {
        this.msgsSeen = newVal.length;
      }
    },
    msgsSeen() {
      if (this.chatSocket.readyState === this.chatSocket.OPEN) {
        this.chatSocket.send(JSON.stringify({
          command: chatEvent.I_HAVE_SEEN,
          chat_id: this.chatId,
          n_seen: this.msgsSeen,
        }));
      }
    },
    msgsUnseen(val) {
      this.$emit('msgsUnseenChanged', val, this.chatId);
    },
    disableSendWithEnter(newVal) {
      sessionStorage.setItem('disableSendWithEnter', newVal);
    },
  },
  async created() {
    this.chatId = this.chat.id;
    this.createModalState(this.chatId);
    this.visitorLanguage = this.chat.visitor_language;
    this.autoTranslationEnabled = this.chat.auto_translation_enabled;

    await this.ensureFullConfigOfTenantFetched({ tenantId: this.tenantId });
    await Promise.all([
      this.ensureLanguagesSetup(this.tenantId),
      this.ensureTenantIntegrationsFetched({ tenantId: this.tenantId }),
      this.fetchChatHistory(),
      this.ensureCannedMsgsFetched({ tenantId: this.tenantId }),
      this.ensureDepartmentCannedMsgsFetched({ departmentId: this.departmentId }),
      this.ensurePretrainedModels(),
      this.ensureSupchatModels(),
      this.ensureSupChatRankers(),
      this.ensureArticleRankers(),
      this.ensureEnabledArticleRankers(),
    ]);

    // set initial agent language
    const languageParams = {
      departmentId: this.departmentId,
      tenantId: this.tenantId,
    };
    // disabled for now
    // const preferredLanguage = this.agentSettings.preferredLanguage;

    // temporary until preferred language is back
    const preferredLanguage = this.getDefaultLanguage(languageParams);

    const agentLanguages = this.getAgentLanguages(languageParams);
    const defaultDepartmentLanguage = this.getDefaultLanguage(languageParams);
    if (preferredLanguage && agentLanguages.includes(preferredLanguage)) {
      this.agentLanguage = preferredLanguage;
    } else {
      this.agentLanguage = defaultDepartmentLanguage;
    }

    const disableSendWithEnter = sessionStorage.getItem('disableSendWithEnter');
    if (disableSendWithEnter) {
      if (disableSendWithEnter === 'true') {
        this.disableSendWithEnter = true;
      } else if (disableSendWithEnter === 'false') {
        this.disableSendWithEnter = false;
      } else {
        sessionStorage.removeItem('disableSendWithEnter');
      }
    }

    if (!this.isEnded) {
      this.ensureSocketConnectionThenRunCallback(this.chatSocket, this.sendJoinMessage);
      if (this.autoStartEnabled && !this.hasSentStartMsg) {
        this.ensureSocketConnectionThenRunCallback(this.chatSocket, this.sendStartMessage);
      }
    }
  },
  mounted() {
    if (this.autoOpen) {
      this.$emit('show-me', this.chatId);
    }
  },
  methods: {
    ...mapActions('tenants', [
      'ensureCannedMsgsFetched',
      'ensureFullConfigOfTenantFetched',
    ]),
    ...mapActions('tenants/predictions', [
      'ensurePretrainedModels',
      'ensureSupchatModels',
      'ensureSupChatRankers',
      'ensureArticleRankers',
      'ensureEnabledArticleRankers',
    ]),
    ...mapActions('chat/chatModals', [
      'createModalState',
      'setCMPredictions',
      'setModalVisible',
      'setMetadataPredictions',
      'setArticlePredictionUUID',
      'setTabIndex',
    ]),
    ...mapActions('chat', [
      'setChatHistory',
      'extractToNotifyMsg',
    ]),
    ...mapActions('departments', [
      'ensureDepartmentCannedMsgsFetched',
    ]),
    ...mapActions('integrations', ['ensureTenantIntegrationsFetched']),
    ...mapActions('tenants/languages', ['ensureLanguagesSetup']),
    addSummaryAsNote(text) {
      this.sendCommandWrapper({
        command: chatEvent.NOTE,
        text,
      });
    },
    setLanguage({ who, language }) {
      if (who === 'agent') {
        this.agentLanguage = language;
        return;
      }
      this.visitorLanguage = language;

      // send changes to backend
      setChatTranslationLanguage({ chatId: this.chatId, language });
    },
    appendUnAckMsg(chatId, text) {
      this.$store.dispatch('chat/appendUnAckMsg', { chatId, text });
    },
    sendCommandWrapper(cmd) {
      sendCommand(this.chatSocket, this.chatId, cmd);
    },
    /* sendFormRequest() {
      const msg = {
        command: chatEvent.FORM_REQUEST,
        language: this.agentLanguage,
        fields: [
          {
            type: 'text',
            id: 'customer_id',
            text: 'Hvad er dit navn?',
          },
          {
            type: 'select',
            id: 'reason',
            text: 'Laver du fejl?',
            options: [
              { id: 'never', text: 'Nej, aldrig!' },
              { id: 'always', text: 'Man er jo lidt en soerend' },
            ],
          },
        ],
      };
      this.sendCommandWrapper(msg);
    }, */
    processCannedMsg(text) {
      return this.$refs.MessageTextarea.processCannedMsg(text);
    },
    sendTextToInput(text) {
      return this.$refs.MessageTextarea.sendTextToInput(text);
    },
    resendMsg(value) {
      const index = this.unAckMsgs.findIndex((x) => x.timestamp === value);
      if (index !== -1) {
        this.$emit('answered-chat', this.chatId);
        const toResendMsg = this.unAckMsgs[index];
        this.unAckMsgs.splice(index, 1);
        sendMessage(this.chatSocket, toResendMsg.text, this.chatId);
        this.appendUnAckMsg(toResendMsg.text, this.chatId);
      }
    },
    ensureSocketConnectionThenRunCallback(socket, callback) {
      if (socket.readyState === 1) {
        if (typeof callback === 'function') {
          callback();
        }
      } else {
        this.$log.debug('wait for chat socket connection...');
        setTimeout(this.ensureSocketConnectionThenRunCallback, 500, socket, callback);
      }
    },
    sendJoinMessage() {
      this.sendCommandWrapper({ command: chatEvent.JOIN });
    },
    sendStartMessage() {
      if (this.isEnded) return;
      this.sendCommandWrapper({
        command: chatEvent.STATUS,
        text: 'start',
      });
      this.focusInputField();
    },
    onHidden() {
      this.setModalVisible({ chatId: this.chatId, status: false });
      this.showEndChatPopover = false;
    },
    onShown() {
      if (!this.isEnded) {
        this.ensureSocketConnectionThenRunCallback(this.chatSocket, this.sendJoinMessage);
      }
      this.setModalVisible({ chatId: this.chatId, status: true });
      this.msgsSeen = this.historySay.length;
    },
    updateMetaData(val) { this.metaData = val; },
    updateCustomMetaData(val) {
      this.customMetaData = val;
      this.saveCustomVisitorInfo();
    },
    async saveCustomVisitorInfo() {
      if (this.customMetaData) {
        await sendCustomVisitorInfo({
          chat_id: this.chatId,
          custom_visitor_info: this.customMetaData,
        });
      }
    },
    async endChatSession() {
      try {
        await endChatSession(this.chatSocket, this.chatId);
        if (this.autoCloseEnabled) this.closeChatWindow();
      } catch (error) {
        this.$log.error(error);
      }
    },
    async closeChatWindow() {
      if (!this.visitorMetaDataReady) {
        this.showCloseChatWindowPopover = !this.showCloseChatWindowPopover;
        return;
      }
      this.showCloseChatWindowPopover = false;
      try {
        await this.saveCustomVisitorInfo();
        this.removeChat();
      } catch (error) {
        this.$log.error(error);
      }
    },
    prepareChatTranscript() {
      let s = metaDataObjectToString(this, {
        ...this.metaData,
        ...this.customMetaData,
      });
      s += '\n';
      s += msgsToString(this, this.history, this.agents);
      return s;
    },
    translateMsg({ uuid }) {
      const msg = this.getMessage(uuid);
      const isAgentMsg = msg.sender_role === 'agent';
      const fromLanguage = isAgentMsg ? msg.language : this.visitorLanguage;
      let toLanguage = isAgentMsg ? this.visitorLanguage : this.agentLanguage;
      if (isAgentMsg && msg.language !== this.agentLanguage) toLanguage = this.agentLanguage;
      return this.sendCommandWrapper({
        command: chatEvent.TRANSLATE_NOW,
        text: uuid,
        from_language: fromLanguage,
        to_language: toLanguage,
      });
    },
    markMsgTranslationAsWrong({ action, msgUuid }) {
      return this.sendCommandWrapper({
        command: action === 'add' ? chatEvent.ADD_TAG : chatEvent.REMOVE_TAG,
        tagged_msg: msgUuid,
        text: 'wrong_translation',
      });
    },
    handleVisitorMetaReady(value) {
      this.visitorMetaDataReady = value;
    },
    removeChat() {
      this.$emit('remove-chat', this.chatId);
      this.$refs.modal.hide();
    },
    sendExternalURILink() {
      if (!this.info.externalUri) return;
      this.sendTextToInput(this.info.externalUri);
      this.$refs.MessageTextarea.sendMessageWrapper();
    },
    focusInputField() {
      this.$refs.MessageTextarea.focusInputField();
    },
    async fetchChatHistory() {
      try {
        const data = await getHistory(this.chatId);
        const history = data.history;
        this.setChatHistory({ chatId: this.chatId, history });
        this.agentId = data.agent_id;
        this.sendersInfo = data.senders_info;
        history.forEach((msg) => {
          if (msg.command === chatEvent.I_HAVE_SEEN) {
            this.msgsSeen = msg.n_seen;
          } else if (msg.command === chatEvent.PREDICTION && msg.type === 'metadata') {
            this.setMetadataPredictions({ chatId: this.chatId, value: msg });
          } else if (msg.command === chatEvent.ARTICLE_PREDICTION) {
            this.setArticlePredictionUUID({ chatId: this.chatId, value: msg.uuid });
          } else if (msg.command === chatEvent.PREDICTION && msg.type === 'canned_message') {
            this.setCMPredictions({
              chatId: this.chatId, value: msg.uuid,
            });
          }
        });
      } catch (error) {
        this.$log.error(error);
      }
    },
    getMessage(uuid) {
      return this.history.find((msg) => msg.uuid === uuid) || {};
    },
    metaDataForVariables() {
      const agent = {
        ...this.info,
        name: this.info.displayName,
      };
      const visitor = this.metaData;
      const custom = this.customMetaData;
      return {
        agent,
        visitor,
        custom,
        conversation: () => this.prepareChatTranscript(),
      };
    },
    toggleAutoTranslation(enabled) {
      this.autoTranslationEnabled = enabled;
    },
    setVisitorMsgAsArticleSearch({ msg, useOriginal }) {
      // get Co-pilot tab index
      const tabsEl = this.$refs.tabs;
      const tabs = [...tabsEl.tabs];
      const AITabIndex = tabs
        .findIndex((tab) => tab.title === this.$t('message.chatModal.predictions.title'));
      this.tabIndex = AITabIndex;
      this.$nextTick(() => {
        const articlesRef = this.$refs.ChatPredictions.$refs.Articles;
        this.$nextTick(() => {
          let text = msg.text;
          const visitorLang = this.visitorLanguage;
          const agentLang = this.agentLanguage;
          if (visitorLang !== agentLang && !useOriginal) {
            const uuid = msg.uuid;
            const translations = this.translations[uuid];
            if (translations) {
              const translationToAgentLanguage = translations
                .find((tMsg) => tMsg.language === agentLang);
              if (translationToAgentLanguage) {
                text = translationToAgentLanguage.text;
              }
            }
          }
          articlesRef.searchQuery = text;
          this.$nextTick(() => {
            articlesRef.$refs.InputField.focus?.();
            articlesRef.searchArticles({ overwriteLanguage: useOriginal ? visitorLang : null });
          });
        });
      });
    },
  },
};
</script>

<style scoped>
  @media (min-width: 576px) {
    :deep(.chat-modal-dialog) {
        width: 90vw;
        max-width: 1024px;
    }
  }
</style>
<style>
  .dropdown .language-list {
    max-height: 30vh;
    overflow-y: auto;
  }
  .cannedlist {
    min-height:50px;
    max-height:100%;
  }
  .cannedlist .list-group-item-action {
    appearance: none;
    background: transparent;
    border: none;
    padding: 2px 5px;
    background-color: #fff;
    border-bottom: 1px #ccc solid;
  }
  .cannedlist .list-group-item-action.current {
    background: #eee;
  }
  .cannedlist .article {
    border-bottom: 1px #ccc solid;
  }
  .cannedlist *:last-child {
    border-bottom: none;
  }
  .chat-modal-dialog {
    height: 90vh!important;
    max-height: 1050px!important;
    min-height: auto!important;
}
/*
  temporary "attention grabber" until people
  get used to the buttons new location
*/
.fade-in-enter-active#close-chat-window-button {
  background: #005f89!important;
  color: #fff!important;
}
</style>
