import Vue from 'vue';
import { isEqual } from 'lodash';

import { reconnectIntervals, chatEvent } from 'supwiz/supchat/constants';
import { deepFreeze } from 'supwiz/supchat/generalUtils';

import { getMyQueue, getHistory } from '@/api/apiList';

import { incomingChatFetchIntervals } from '@/utils/constants';

// importing currentTime directly because composables are not supported in vuex 3
import { currentTime } from '@/composables/currentTime';

const chatState = {
  incomingChats: [],
  lastIncomingChatsRefresh: 0,
  failedIncomingFetchAttempts: 0,
  incomingChatsInterval: null,
  awaitingIncomingChatsRefresh: false,
};

/*
  used in updateSingleIncomingChat's update flow to avoid spam
  if a chat id exists in the Set, it means that we are about to update it
  so we do nothing. Once it's fetched shortly after, it's removed from the Set
  allowing future calls to trigger another fetch.
*/
const individualChatsAwaitingUpdate = new Set();

let fetchingIncomingChats = false;

const chatGetters = {
  visibleIncomingChats: (state, getters, rootState, rootGetters) => state.incomingChats
    .filter((chat) => {
      if (Array.isArray(chat.msgs)) {
        const isClosed = chat.msgs.some((msg) => msg.command === 'status' && msg.text === 'close');
        if (isClosed) return false;
      }
      const agentId = rootGetters['agent/id'];
      const departmentsFilter = rootState.agent.settings.blob.hiddenDepartmentsIncoming;
      // if we are not able to get our filter or if the chat doesn't have a department
      // then we display it regardless
      if (!Array.isArray(departmentsFilter) || !chat?.department_id) return true;
      return !departmentsFilter.includes(chat.department_id) || chat.assigned_to_agent === agentId;
    }),
  visibleIncomingChatIDs: (state, getters) => getters.visibleIncomingChats.map(({ id }) => id),
  getIncomingChatFromID: (state) => (chatId) => state.incomingChats
    .find(({ id }) => id === chatId),
  getChatKPITarget: (state, getters, rootState, rootGetters) => (chatId) => {
    // returns KPI target for queue time in seconds

    // Queue time KPI can be either tenant or department. We need to find out first
    const chat = getters.getIncomingChatFromID(chatId);

    /*
      If we for some reason cannot get the target value
      we return Infinity to avoid triggering any KPI notifications
    */
    if (!chat) return Infinity;
    const tenantId = chat.tenant;
    const KPIConfig = rootGetters['tenants/getConfig']({ tenantId, tag: 'kpi' });

    // quick check to see if the config was found
    if (typeof KPIConfig.apply !== 'boolean') return Infinity;

    // if apply is true then we use tenant KPI
    if (KPIConfig.apply) return KPIConfig.chat_response_time;

    // otherwise, find department KPI target
    const department = rootGetters['departments/getSingleDepartment'](chat.department_id);
    if (!department) return Infinity;
    return department.kpi_chat_response_time;
  },
  getChatCurrentQueueStartTime: (state, getters) => (chatId) => {
    const chat = getters.getIncomingChatFromID(chatId);
    if (!chat) return 0;
    if (!Array.isArray(chat?.msgs)) return 0;
    const chatStatusMsgs = chat.msgs.filter((msg) => msg.command === chatEvent.STATUS);
    if (!chatStatusMsgs?.length) {
      // if no status msgs were found, we return the timestamp of the first join message
      const firstJoinMsg = chat?.msgs?.find(({
        command, sender_role: role,
      }) => command === chatEvent.JOIN && role === 'visitor');
      if (firstJoinMsg) return ((firstJoinMsg?.timestamp || 0) * 1000);
    }
    for (let i = chatStatusMsgs.length - 1; i >= 0; i--) {
      if (chatStatusMsgs[i].text === 'transfer') {
        const timestamp = chatStatusMsgs[i].timestamp * 1000;
        return timestamp;
      }
    }
    // if we somehow failed to find anything so far then we just use the first message's timestamp
    return ((chat.msgs[0]?.timestamp || 0) * 1000);
  },
  getChatCurrentQueueTime: (state, getters) => (chatId) => (
    currentTime.value - getters.getChatCurrentQueueStartTime(chatId)
  ),
  kpiApproachChatIDs: (state, getters) => getters.visibleIncomingChats.filter(
    (chat) => {
      const kpiTarget = getters.getChatKPITarget(chat.id);
      const queueTime = getters.getChatCurrentQueueTime(chat.id) / 1000; // convert to seconds
      return queueTime > (kpiTarget * 0.66)
        && queueTime < kpiTarget;
    },
  ).map(({ id }) => id),
  kpiBreachChatIDs: (state, getters) => getters.visibleIncomingChats.filter(
    (chat) => {
      const kpiTarget = getters.getChatKPITarget(chat.id);
      const queueTime = getters.getChatCurrentQueueTime(chat.id) / 1000; // convert to seconds
      return queueTime >= kpiTarget;
    },
  ).map(({ id }) => id),
};
const mutations = {
  UPDATE_LAST_INCOMING_REFRESH_TIME(state, lastUpdate) {
    state.lastIncomingChatsRefresh = lastUpdate;
  },
  SET_INCOMING_CHATS(state, chats) {
    state.incomingChats = [...chats.map((chat) => deepFreeze(chat))];
  },
  ADD_INCOMING_CHAT(state, chat) {
    state.incomingChats.push(deepFreeze(chat));
  },
  UPDATE_INCOMING_CHAT(state, { chatIndex, updatedChatObj }) {
    Vue.set(state.incomingChats, chatIndex, deepFreeze(updatedChatObj));
  },
  DELETE_INCOMING_CHAT(state, { chatIndex }) {
    Vue.delete(state.incomingChats, chatIndex);
  },
  SET_INCOMING_POLLING_INTERVAL(state, pollingFunction) {
    state.incomingChatsInterval = setInterval(
      pollingFunction,
      incomingChatFetchIntervals.interval,
    );
  },
  SET_INCOMING_RECONNECT_ATTEMPTS(state, attempt) {
    state.failedIncomingFetchAttempts = attempt;
  },
  CLEAR_INCOMING_POLLING_INTERVAL(state) {
    clearInterval(state.incomingChatsInterval);
    state.incomingChatsInterval = null;
  },
  SET_AWAITING_INCOMING_CHATS_REFRESH(state, isAwaiting) {
    state.awaitingIncomingChatsRefresh = isAwaiting;
  },
};

const actions = {
  beginPollingIncomingChats({ state, commit, dispatch }) {
    /*
      If controlSocket or the New Chats page forces a refresh,
      we clear the interval to prevent spam.
    */

    // Start polling interval if not started or cleared
    if (state.incomingChatsInterval === null) {
      dispatch('refreshAllIncomingChats');
      commit('SET_INCOMING_POLLING_INTERVAL', () => dispatch('refreshAllIncomingChats'));
    }
  },
  /**
   * Fetches ALL incoming chats if at least (incomingRefreshInterval)
   * seconds has passed since the last time
   * this function was called. Individual chats can be updated in between refreshes.
   */
  async refreshAllIncomingChats({
    commit, state, dispatch,
  }, payload) {
    if (fetchingIncomingChats) return;
    try {
      const now = currentTime.value;

      if (!payload?.bypassTimeCheck) {
        const lastRefresh = state.lastIncomingChatsRefresh;

        /*
          The control socket will inform us of new chats and other updates
          It does this by setting awaitingIncomingChatsRefresh. If it's true
          then we use our min interval, otherwise we use the max
        */
        const refreshTime = state.awaitingIncomingChatsRefresh
          ? incomingChatFetchIntervals.min
          : incomingChatFetchIntervals.max;
        // check if enough time has passed since last
        if ((now - lastRefresh) < refreshTime) return;
      }

      // set our fetching status to true to prevent spam
      fetchingIncomingChats = true;

      commit('SET_AWAITING_INCOMING_CHATS_REFRESH', false);
      const { chats } = await getMyQueue({ filter: 'incoming', includeMessages: true });
      if (!isEqual(state.incomingChats, chats)) commit('SET_INCOMING_CHATS', chats);
      commit('UPDATE_LAST_INCOMING_REFRESH_TIME', now);

      /*
          If a previous fetch has failed and we are not in the catch block yet
          that means it didn't fail and we can reset the failed attempts.
        */
      if (state.failedIncomingFetchAttempts !== 0) {
        commit('SET_INCOMING_RECONNECT_ATTEMPTS', 0);
      }
    } catch (error) {
      if (error?.response?.status === 0) document.location.reload();
      Vue.$log.error(error);
      commit('CLEAR_INCOMING_POLLING_INTERVAL');

      const delay = reconnectIntervals[state.failedIncomingFetchAttempts];

      if (state.failedIncomingFetchAttempts < reconnectIntervals.length) {
        setTimeout(() => {
          commit('SET_INCOMING_RECONNECT_ATTEMPTS', state.failedIncomingFetchAttempts + 1);
          dispatch('beginPollingIncomingChats');
        }, delay * 1000);
      }
    } finally {
      fetchingIncomingChats = false;
    }
  },
  /**
   * Update the state of a single incoming chat. This can be updating chatlog or
   * removing (deleting) it from the incoming state if it has ended.
   * @param {Object} vuex Vuex
   * @param {Object} object object containing chat ID and task ID
   * @param {string} object.chatId chat ID of the chat we want to update or delete
   * @param {('add'|'delete'|'update')} object.task specify if we want to delete or update the chat.
   */
  async updateSingleIncomingChat({ commit, state, getters }, { chatId, task, chatObj = null }) {
    if (task === 'add' && chatObj) {
      // check if chat already exists because then we don't want to add it again
      if (getters.getIncomingChatFromID(chatId)) return;
      commit('ADD_INCOMING_CHAT', chatObj);
      return;
    }

    const indexOfChat = state.incomingChats.findIndex(({ id }) => id === chatId);
    if (indexOfChat === -1) {
      Vue.$log.error(`tried to ${task} "${chatId}" but doesn't exist in state`);
      return;
    }
    if (task === 'delete') {
      commit('DELETE_INCOMING_CHAT', { chatIndex: indexOfChat });
      return;
    }
    if (task === 'update') {
      /**
       *  no need to update the logs if we cant see them
       *  hacky, there is a dependency cycle issue if I import router
       *
       * also check if we are already about to update the chat history
       * if so, do nothing and await the update instead.
      */
      const isOnIncomingPage = document.location.pathname.includes('/incoming');
      if (!isOnIncomingPage || individualChatsAwaitingUpdate.has(chatId)) return;
      individualChatsAwaitingUpdate.add(chatId);
      setTimeout(async () => {
        const chat = await getHistory(chatId);
        const newMsgs = chat.history;
        const currentChatObj = state.incomingChats[indexOfChat];
        commit('UPDATE_INCOMING_CHAT', {
          chatIndex: indexOfChat,
          updatedChatObj: { ...currentChatObj, msgs: newMsgs },
        });
        individualChatsAwaitingUpdate.delete(chatId);
      }, incomingChatFetchIntervals.interval);
    }
  },
};

export default {
  state: chatState,
  getters: chatGetters,
  mutations,
  actions,
};
