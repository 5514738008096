import Cookies from 'js-cookie';
import { prepareGetParameters } from './generalUtils';
import { supchatSessionHeaderName, visitorIdentifierCookie } from './constants';

export const fetchConfig = { isVisitor: false, visitorCookieDomain: null };

export const SUPCHAT_API = (typeof process !== 'undefined' && process.env.VUE_APP_SUPCHAT_API)
  ? process.env.VUE_APP_SUPCHAT_API
  : window.location.hostname + (window.location.port ? `:${window.location.port}` : '');

function getHostDomain() {
  if (fetchConfig.visitorCookieDomain) {
    return fetchConfig.visitorCookieDomain;
  }
  if (window.location.hostname.endsWith('supwizapp.com')) {
    return `.${window.location.hostname}`;
  }
  if (window.location.hostname.endsWith('service-now.com')
    || window.location.hostname.endsWith('nnitcorp.com')) {
    const parts = window.location.hostname.split('.');
    if (parts.length <= 2) {
      return `.${window.location.hostname}`;
    }
    return `.${parts[parts.length - 3]}.${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
  }
  const parts = window.location.hostname.split('.');
  if (parts.length === 1) {
    return `.${parts[0]}`;
  }
  return `.${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
}

export const SUPCHAT_SESSION_COOKIE_ATTRIBUTES = {
  secure: true,
  sameSite: 'strict',
  domain: getHostDomain(),
  expires: 7, // expires after 7 days
};

export function getClientSideVisitorSessionCookie() {
  return Cookies.get(visitorIdentifierCookie);
}
export function setClientSideVisitorSessionCookie(value) {
  Cookies.set(visitorIdentifierCookie, value, SUPCHAT_SESSION_COOKIE_ATTRIBUTES);
}

function xhrRequest(endpoint, data = {}, method = 'GET', asynchronous = true) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    let sendData = '';
    if (['POST', 'DELETE', 'PUT'].includes(method.toUpperCase())) {
      sendData = JSON.stringify(data);
    }

    xhr.open(method, endpoint, asynchronous);
    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    if (fetchConfig.isVisitor) {
      const supchatSessionId = getClientSideVisitorSessionCookie();
      if (supchatSessionId) {
        xhr.setRequestHeader(supchatSessionHeaderName, supchatSessionId);
      }
    } else {
      xhr.setRequestHeader('x-csrftoken', Cookies.get('csrftoken'));
    }
    xhr.send(sendData);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.DONE) {
        if ((xhr.status >= 200 && xhr.status < 300) || xhr.status === 304) {
          let resp = xhr.response;
          if (fetchConfig.isVisitor) {
            // set cookie
            setClientSideVisitorSessionCookie(xhr.getResponseHeader(supchatSessionHeaderName));
          }
          if (typeof resp !== 'object') {
            resp = JSON.parse(resp);
          }
          resolve(resp);
        } else {
          reject(xhr);
        }
      }
    };
  });
}

/**
 * Make a valid http request.
 * @param {string} url
 * @param {object} data
 * @param {string} method - one of the http methods {'GET', 'POST', 'DELETE', 'PUT'}
 * @param {string | null} baseURL - if not provided, base url is the endpoint of supchat
 * @param {boolean} asynchronous - whether to be an async or a sync call
 * @param {string} credentials  - whether to include cookie when send the request
 * @param {object} fileUpload  - if not null, should be FormData()
 * @returns {*}
 */
async function fetchData(
  {
    url,
    data = {},
    method = 'GET',
    baseURL = null,
    asynchronous = true,
    fileUpload = null,
    overrideCredentials = false,
  } = {}) {
  let mode;
  let credentials;
  if (fetchConfig.isVisitor) {
    // We want to be able to dynamically provide a default value for credentials.
    credentials = overrideCredentials || 'omit';
    mode = 'cors';
  } else {
    // We want to be able to dynamically provide a default value for credentials.
    credentials = 'include';
    mode = 'same-origin';
  }
  const tcpProtocol = window.location.protocol;
  let BASE_URL;
  if (baseURL == null) {
    BASE_URL = `${tcpProtocol}//${SUPCHAT_API}`;
  } else if (baseURL.startsWith('http')) {
    BASE_URL = baseURL;
  } else {
    BASE_URL = `${tcpProtocol}//${baseURL}`;
  }

  let endpoint = BASE_URL + url;

  if (method.toUpperCase() === 'GET') {
    const paramStr = prepareGetParameters(data);
    if (paramStr !== '') {
      endpoint = `${endpoint}?${paramStr}`;
    }
  }

  if (!window.fetch) return xhrRequest(endpoint, data, method, asynchronous);
  const headers = {};
  if (!fileUpload) headers['content-type'] = 'application/json';
  if (fetchConfig.isVisitor) {
    const visitorSessionCookie = getClientSideVisitorSessionCookie();
    if (visitorSessionCookie) {
      headers[supchatSessionHeaderName] = visitorSessionCookie;
    }
  } else {
    headers['x-csrftoken'] = Cookies.get('csrftoken');
  }
  const config = {
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials, // include, same-origin, *omit
    headers,
    method,
    mode,
    redirect: 'manual', // manual, *follow, error
    referrer: 'no-referrer', // *client, no-referrer
  };

  if (['POST', 'DELETE', 'PUT', 'PATCH'].includes(method.toUpperCase())) {
    if (fileUpload) {
      config.body = fileUpload;
    } else {
      Object.defineProperty(config, 'body', {
        value: JSON.stringify(data),
      });
    }
  }
  const resp = await window.fetch(endpoint, config);
  if (resp.status === 0) {
    // This may happen for a lot a reasons, but they all mean something went very wrong
    const error = new Error('Did servers get updated? Or CORS problems?');
    error.response = resp;
    throw error;
  }
  if (fetchConfig.isVisitor) {
    const visitorSessionAuthHeaderValue = resp.headers.get(supchatSessionHeaderName);
    if (visitorSessionAuthHeaderValue) {
      setClientSideVisitorSessionCookie(visitorSessionAuthHeaderValue);
    }
  }
  /*
    The status code is used in the vuex store in some places,
    so any changes here might break something.
  */
  if ([200, 201].includes(resp.status)) return resp.json();
  if (resp.status < 400) return resp;
  const error = new Error(resp.status);
  error.response = resp;
  try {
    const jsonResp = await resp.json();
    error.json = jsonResp;
  } catch (err) {
    error.json = {};
    error.errorMsg = err;
  }
  throw error;
  /*
    If you need the statuscode for error handling, you can do it like this
    ...catch (error) { if (error.response.status === 502 { ...
  */
}

export default fetchData;
