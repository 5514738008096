<template>
  <b-row
    class="justify-content-md-center pt-5"
    no-gutters
  >
    <b-col cols="6">
      <b-card
        align="center"
        :title="$t('message.loginPage.title')"
      >
        <b-alert
          v-if="errorCode === 1"
          show
          variant="danger"
        >
          {{ $t('message.loginPage.serverDown') }}
        </b-alert>
        <b-alert
          v-if="errorCode === 2"
          show
          variant="danger"
        >
          {{ $t('message.loginPage.invalid') }}
        </b-alert>
        <b-alert
          v-if="errorCode === 3"
          show
          variant="danger"
        >
          {{ $t('message.loginPage.permissionDeniedSupchat') }}
        </b-alert>
        <b-alert
          v-if="errorCode === 4"
          show
          variant="danger"
        >
          {{ $t('message.loginPage.unexpectedError') }}
        </b-alert>
        <b-alert
          v-if="errorCode === 5"
          show
          variant="danger"
        >
          {{ $t('message.loginPage.permissionDeniedAgent') }}
        </b-alert>
        <b-alert
          v-if="errorCode === 999"
          show
          variant="danger"
        >
          {{ $t('message.loginPage.unknownError') }}
        </b-alert>
        <b-form @submit="onSubmit">
          <b-form-group
            class="text-left"
            :label="$t('vocabulary.username')"
            label-for="username"
            label-size="sm"
          >
            <b-form-input
              id="username"
              ref="usernameInput"
              v-model="username"
              type="text"
              :placeholder="$t('message.loginPage.usernamePlace')"
              autocomplete="username"
              autofocus
              required
            />
          </b-form-group>

          <b-form-group
            class="text-left"
            :label="$t('vocabulary.password')"
            label-for="password"
            label-size="sm"
          >
            <b-form-input
              id="password"
              v-model="password"
              type="password"
              :placeholder="$t('message.loginPage.passwordPlace')"
              autocomplete="current-password"
              required
            />
          </b-form-group>

          <b-button
            type="submit"
            variant="primary"
            style="width:100%"
            class="mb-2"
          >
            {{ $t('message.signIn') }}
          </b-button>
          <div
            v-if="ssoEnabled"
            class="mb-2"
          >
            <a href="/control/sso/login">
              {{ $t('message.signInAD') }}
            </a>
          </div>
          <div
            class="mb-2"
          >
            <a href="/accounts/password_reset/">
              {{ $t('message.forgottenPassword') }}
            </a>
          </div>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { isSSOInstalled } from '@/api/apiList';
import { mapState } from 'vuex';

export default {
  name: 'LoginPage',
  components: {
  },
  data() {
    return {
      ssoEnabled: false,
      username: '',
      password: '',
      // flag for checking error source, 0 for normal
      // 1 for server down and 2 for wrong username/password
      errorCode: 0,
    };
  },
  computed: {
    ...mapState(['featureFlags']),
  },
  async created() {
    const [, isSSOEnabled] = await Promise.all([
      this.$store.dispatch('ensureFeatureFlags'),
      isSSOInstalled(),
    ]);
    this.ssoEnabled = isSSOEnabled;
    if (this.$route.query.error === 'invalid-grant-aad') {
      this.errorCode = 3;
    } else if (this.$route.query.error === 'unexpected-aad-error') {
      this.errorCode = 4;
    }
    const redirectPath = this.$route?.query?.redirect;
    if (redirectPath) localStorage.setItem('redirect-path', redirectPath);
  },
  methods: {
    async onSubmit(evt) {
      evt.preventDefault();
      try {
        const credentials = { username: this.username, password: this.password };
        await this.$store.dispatch('agent/login', credentials);
        this.$router.push({ name: 'home' });
      } catch (error) {
        if (!error.response) {
          this.errorCode = 999;
        } else if (error.response.status === 502) {
          this.errorCode = 1;
        } else if (error.response.status === 401) {
          this.errorCode = 2;
        } else if (error.response.status === 403) {
          this.errorCode = 5;
        } else {
          this.errorCode = 999;
        }
        this.$log.error(error);
      }
    },
  },
};
</script>

<style scoped>
  #login {
    margin: 50px auto;
    border: 1px solid #eee;
    padding: 20px;
    box-shadow: 0 2px 3px #ccc;
  }
</style>
