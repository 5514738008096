import { chatEvent } from 'supwiz/supchat/constants';

export default {
  data() {
    return {
      unAckMessages: [],
    };
  },
  watch: {
    historyNew(newVal, oldVal) {
      const newAckMsgs = [];
      for (const msg of newVal.slice(oldVal.length)) {
        if (msg.command === chatEvent.SAY && Object.prototype.hasOwnProperty.call(msg, 'uuid')) {
          newAckMsgs.push(msg.text);
        }
      }

      this.unAckMessages = this.unAckMessages.filter(
        (x) => !newAckMsgs.includes(x.text),
      );
    },
  },
  methods: {
    appendUnAckMsg(message, chatId) {
      this.unAckMessages.push({
        command: chatEvent.SAY,
        chat_id: chatId,
        text: message,
        timestamp: new Date().getTime() / 1000,
      });
    },
  },
};
