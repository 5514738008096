import { render, staticRenderFns } from "./ToolDetails.vue?vue&type=template&id=2462c6f7&scoped=true"
import script from "./ToolDetails.vue?vue&type=script&lang=js"
export * from "./ToolDetails.vue?vue&type=script&lang=js"
import style0 from "./ToolDetails.vue?vue&type=style&index=0&id=2462c6f7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../products/supchat/agent/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2462c6f7",
  null
  
)

export default component.exports