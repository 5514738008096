import Vue from 'vue';
import VueRouter from 'vue-router';
// eslint-disable-next-line import/no-cycle
import { i18n } from '@/localization';

import LoginPage from '@/pages/LoginPage.vue';
import HomePage from '@/pages/HomePage.vue';
import ArchivePage from '@/pages/ArchivePage.vue';
import IncomingChatsPage from '@/pages/IncomingChatsPage.vue';
import OngoingChatsPage from '@/pages/OngoingChatsPage.vue';
import LogoutPage from '@/pages/LogoutPage.vue';
import store from '../store/index';

const ArchivePageSingle = () => import('@/pages/ArchivePageSingle.vue');

// Settings pages
const CannedMsgsPage = () => import('@/pages/settingsPage/TenantCannedMessages.vue');
const SettingsPage = () => import('@/pages/SettingsPage.vue');
const TenantGeneral = () => import('@/pages/settingsPage/TenantGeneral.vue');
const TenantKpi = () => import('@/pages/settingsPage/TenantKpi.vue');
const TenantOpeningHours = () => import('@/pages/settingsPage/TenantOpeningHours.vue');
const TenantAnonymization = () => import('@/pages/settingsPage/TenantAnonymization.vue');
const TenantIntegrations = () => import('@/pages/settingsPage/TenantIntegrations.vue');
const TenantTranslations = () => import('@/pages/settingsPage/TenantTranslations.vue');
const TenantChatWidgetConfigs = () => import('@/pages/settingsPage/TenantChatWidgetConfigs.vue');
const TenantInactivityMessages = () => import('@/pages/settingsPage/TenantInactivityMessages.vue');
const TenantPredictions = () => import('@/pages/settingsPage/TenantPredictions.vue');
const TenantAutoAssign = () => import('@/pages/settingsPage/TenantAutoAssign.vue');

Vue.use(VueRouter);

const AnalyticsPage = () => import('@/pages/AnalyticsPage.vue');
const InsightsPage = () => import(/* webpackChunkName: "insights" */ '@/pages/InsightsPage.vue');
const DashboardPage = () => import(/* webpackChunkName: "insights" */ '@/pages/insightsPage/DashboardPage.vue');
const NoDashboardPage = () => import(/* webpackChunkName: "insights" */ '@/pages/insightsPage/NoDashboardPage.vue');
const getAdministrationPage = () => import('@/pages/Administration.vue');
const getTenantManagementPage = () => import('@/pages/TenantManagement.vue');
const getUserManagementPage = () => import('@/pages/UserManagement.vue');

const UserSettingsPage = () => import('@/pages/SettingsUser.vue');

function breadcrumb(route, params) {
  if (route.name === 'archive-single') {
    return { display: params.chatId, link: { fullPath: document.location.href } };
  }
  if (route.name === 'entry-widget-selected') {
    return { display: params.configId, link: { fullPath: document.location.href } };
  }
  const metaTitle = route?.meta?.title;
  const pageTitle = metaTitle ? i18n.t(metaTitle) : ' ';
  return { display: pageTitle, link: { name: route.name } };
}

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: {
      title: 'message.pageLogin',
      breadcrumb,
    },
  },
  {
    path: '/home',
    name: 'home',
    component: HomePage,
    meta: {
      requiresAuth: true,
      title: 'message.pageHome',
      breadcrumb,
    },
    children: [
      {
        path: 'my-settings',
        name: 'my-settings',
        component: UserSettingsPage,
        meta: {
          requiresAuth: true,
          title: 'userSettings.pageTitle',
          breadcrumb,
        },
      },
    ],
  },
  {
    path: '/incoming',
    name: 'incoming',
    component: IncomingChatsPage,
    meta: {
      requiresAuth: true,
      title: 'message.pageIncoming',
      breadcrumb,
    },
  },
  {
    path: '/visitors',
    name: 'ongoing',
    component: OngoingChatsPage,
    meta: {
      requiresAuth: true,
      title: 'message.pageOngoing',
      breadcrumb,
    },
  },
  {
    path: '/history',
    name: 'archive',
    component: ArchivePage,
    meta: {
      requiresAuth: true,
      title: 'message.pageArchive',
      breadcrumb,
    },
  },
  {
    path: '/history-single/:chatId',
    name: 'archive-single',
    component: ArchivePageSingle,
    meta: {
      requiresAuth: true,
      title: 'message.pageArchive',
      breadcrumb,
    },
  },
  {
    path: '/insights',
    component: InsightsPage,
    name: 'insights',
    redirect: { name: 'insightsNoDashboard' },
    meta: {
      requiresAuth: true,
      title: 'message.pageInsights',
      breadcrumb,
    },
    children: [
      {
        path: '',
        name: 'insightsNoDashboard',
        component: NoDashboardPage,
        meta: {
          requiresAuth: true,
          title: 'message.pageInsights',
          breadcrumb,
        },
      },
      {
        path: ':dashboardId',
        name: 'dashboard',
        component: DashboardPage,
        meta: {
          requiresAuth: true,
          title: 'message.pageInsights',
          breadcrumb,
        },
      },
    ],
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('@/pages/ReportPage.vue'),
    meta: {
      requiresAuth: true,
      title: 'analytics.report.pageTitle',
      breadcrumb,
    },
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: AnalyticsPage,
    meta: {
      requiresAuth: true,
      title: 'message.analytics.title',
      breadcrumb,
    },
  },
  {
    path: '/config',
    name: 'settings',
    redirect: { name: 'settings-general' },
    component: SettingsPage,
    meta: {
      requiresAuth: true,
      title: 'message.pageSettings',
      breadcrumb,
    },
    children: [
      {
        path: 'general',
        name: 'settings-general',
        component: TenantGeneral,
        meta: {
          requiresAuth: true,
          title: 'vocabulary.general',
          breadcrumb,
        },
      },
      {
        path: 'kpi',
        name: 'settings-kpi',
        component: TenantKpi,
        meta: {
          requiresAuth: true,
          title: 'vocabulary.kpi',
          breadcrumb,
        },
      },
      {
        path: 'opening_hours',
        name: 'settings-opening_hours',
        component: TenantOpeningHours,
        meta: {
          requiresAuth: true,
          title: 'settings.openingHours.pageTitle',
          breadcrumb,
        },
      },
      {
        path: 'anonymization',
        name: 'settings-anonymization',
        component: TenantAnonymization,
        meta: {
          requiresAuth: true,
          title: 'vocabulary.anonymization',
          breadcrumb,
        },
      },
      {
        path: 'integrations',
        name: 'settings-integrations',
        component: TenantIntegrations,
        meta: {
          requiresAuth: true,
          title: 'settings.integrations.title',
          breadcrumb,
        },
      },
      {
        path: 'languages',
        name: 'settings-languages',
        component: TenantTranslations,
        meta: {
          requiresAuth: true,
          title: 'settings.languages.pageTitle',
          breadcrumb,
        },
      },
      {
        path: 'widget_configs',
        name: 'settings-widget_configs',
        component: TenantChatWidgetConfigs,
        meta: {
          requiresAuth: true,
          title: 'vocabulary.widgetConfigs',
          breadcrumb,
        },
      },
      {
        path: 'inactivity_message',
        name: 'settings-inactivity_message',
        component: TenantInactivityMessages,
        meta: {
          requiresAuth: true,
          title: 'message.waitingMessages.pageTitle',
          breadcrumb,
        },
      },
      {
        path: 'prediction',
        name: 'settings-prediction',
        component: TenantPredictions,
        meta: {
          requiresAuth: true,
          title: 'settings.prediction.title',
          breadcrumb,
        },
      },
      {
        path: 'canned-messages',
        name: 'canned-messages',
        component: CannedMsgsPage,
        meta: {
          requiresAuth: true,
          title: 'message.pageCanned',
          breadcrumb,
        },
      },
      {
        path: 'auto-assign',
        name: 'auto-assign',
        component: TenantAutoAssign,
        meta: {
          requiresAuth: true,
          title: 'settings.autoAssign.pageTitle',
          breadcrumb,
        },
      },
    ],
  },
  {
    path: '/administration',
    name: 'administration',
    component: getAdministrationPage,
    redirect: { name: 'tenant-management' },
    meta: {
      requiresAuth: true,
      title: 'vocabulary.administration',
      breadcrumb,
    },
    children: [
      {
        path: 'tenant-management',
        name: 'tenant-management',
        component: getTenantManagementPage,
        meta: {
          requiresAuth: true,
          title: 'message.pageTenantConfig',
          breadcrumb,
        },
      },
      {
        path: 'user-management',
        name: 'user-management',
        component: getUserManagementPage,
        meta: {
          requiresAuth: true,
          title: 'message.pageUserManagement',
          breadcrumb,
        },
      },
      {
        path: 'entry-widget',
        name: 'entry-widget',
        component: () => import('@/pages/EntryWidgetConfig.vue'),
        redirect: { name: 'entry-widget-none' },
        meta: {
          requiresAuth: true,
          title: 'administration.widget.pageTitle',
          breadcrumb,
        },
        children: [
          {
            path: '',
            name: 'entry-widget-none',
            component: () => import('@/pages/EntryWidgetConfig/PageNoWidgetSelected.vue'),
            meta: {
              requiresAuth: true,
              title: 'administration.widget.pageTitle',
              breadcrumb,
            },
          },
          {
            path: ':configId',
            name: 'entry-widget-selected',
            component: () => import('@/pages/EntryWidgetConfig/PageWidgetSelected.vue'),
            meta: {
              requiresAuth: true,
              title: 'administration.widget.pageTitle',
              breadcrumb,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutPage,
    meta: {
      requiresAuth: true,
      title: 'message.signOut',
      breadcrumb,
    },
  },
  { path: '*', redirect: '/home' },
];

const router = new VueRouter({
  mode: 'history',
  base: '/dashboard/',
  routes,
});

router.beforeEach(async (to, from, next) => {
  try {
    const authRequired = to.matched.some((route) => route.meta.requiresAuth);
    const isLoggedIn = await store.dispatch('agent/checkStatus');
    if (authRequired && !isLoggedIn && to.name !== 'login') {
      next({ name: 'login', query: { redirect: to.path } });
    } else if (to.name === 'login' && isLoggedIn) {
      next({ name: 'home' });
    } else {
      document.title = i18n.t('message.pageMetaTitle', { page: i18n.t(to.meta.title) });
      next();
    }
  } catch (error) {
    next({ name: 'login' });
  }
});

export default router;
