import { chatEvent } from 'supwiz/supchat/constants';
import Vue from 'vue';

const modalsState = {
};

const modalGetters = {
  getModalValue: (state) => ({ chatId, field }) => state?.[chatId]?.[field],
  isModalVisible: (state, getters) => (chatId) => getters
    .getModalValue({ chatId, field: 'visible' }),
  getPredictedCannedMessages: (state, getters, rootState, rootGetters) => (chatId) => {
    const predictionMsgUUID = getters.getModalValue({ chatId, field: 'cannedMessageP' });
    if (!predictionMsgUUID) return [];
    let predictionMsg;
    let latestVisitorSayMsg;
    const history = rootGetters['chat/getChatHistory'](chatId);
    for (let index = history.length - 1; index >= 0; index--) {
      const { command, sender_role: role, uuid } = history[index];
      // set prediction msg if uuid matches
      if (uuid === predictionMsgUUID) predictionMsg = history[index];

      // if not a say message, continue to next msg
      if (command !== chatEvent.SAY) continue;

      // if first found say message is from agent, then ignore predictions
      if (role === 'agent') return [];

      // translation msg
      if (role === 'system') {
        const translatedMsg = history.find((msg) => msg.uuid === uuid);
        // confirm a match is found and that the sender was visitor
        if (!translatedMsg || translatedMsg.sender_role !== 'visitor') continue;
        latestVisitorSayMsg = history[index];
        break;
      }

      // finally, visitor say message
      // save the msg, stop the loop, and continue
      if (role === 'visitor') {
        latestVisitorSayMsg = history[index];
        break;
      }
    }

    // if no predictions were found, then stop
    if (!predictionMsg) return [];
    if ([predictionMsg?.prediction_for, latestVisitorSayMsg?.uuid].includes(undefined)) return [];
    if (predictionMsg.prediction_for !== latestVisitorSayMsg.uuid) return [];
    return Object.entries(predictionMsg.prediction);
  },
  getPredictedMetadata: (state, getters) => (chatId) => getters
    .getModalValue({ chatId, field: 'metaP' }) || {},
  getArticlesMsgUUID: (state, getters) => (chatId) => getters
    .getModalValue({ chatId, field: 'articlesUUID' }),
  chatModalIds: (state) => Object.keys(state),
};

const mutations = {
  CREATE_MODAL_STATE(state, {
    chatId, cannedMessageP, metaP, articlesUUID,
  }) {
    const modalState = {
      input: '',
      visible: false,
      tabIndex: 0,
      visitorFileUploadCache: {},
    };
    if (cannedMessageP) modalState.cannedMessageP = '';
    if (metaP) modalState.metaP = {};
    if (articlesUUID) modalState.articlesUUID = '';
    Vue.set(state, chatId, modalState);
  },
  DELETE_MODAL_STATE(state, chatId) {
    if (!(chatId in state)) {
      Vue.$log.error('cant find chat to remove');
      return;
    }
    Vue.delete(state, chatId);
  },
  SET_MODAL_VALUE(state, {
    chatId,
    field,
    value,
  }) {
    state[chatId][field] = value;
  },
  PUSH_MODAL_VALUE(state, {
    chatId,
    field,
    value,
  }) {
    state[chatId][field].push(value);
  },
  SET_META_PREDICTION(state, {
    chatId,
    value,
  }) {
    if (!(value.target in state[chatId].metaP)) {
      Vue.set(state[chatId].metaP, value.subtype, []);
    }
    state[chatId].metaP[value.subtype] = Object.entries(value.prediction)
      .map(([metadataValue, score]) => ({ value: metadataValue, score, target: value.subtype }));
  },
  SET_VISITOR_FILE(state, {
    chatId,
    value,
  }) {
    Vue.set(state[chatId].visitorFileUploadCache, value.uuid, {
      url: value.url,
      accept: value.accept,
    });
  },
};

const actions = {
  createModalState({ commit, rootState }, chatId) {
    const {
      AI_COPILOT_ENABLED,
      AI_COPILOT_SUPSEARCH_ENABLED,
    } = rootState.featureFlags;
    commit('CREATE_MODAL_STATE', {
      chatId,
      cannedMessageP: AI_COPILOT_ENABLED,
      metaP: AI_COPILOT_ENABLED,
      articlesUUID: AI_COPILOT_SUPSEARCH_ENABLED,
    });
  },
  deleteModalState({ commit }, chatId) {
    commit('DELETE_MODAL_STATE', chatId);
  },
  // eslint bugs out for me with indentation here
  // eslint-disable-next-line object-curly-newline
  internalSet({ state, commit, getters, dispatch },
    { chatId, field, value }) {
    if (!(chatId in state)) dispatch('createModalState', chatId);
    const args = { chatId, field };
    if (getters.getModalValue(args) === value) return;
    commit('SET_MODAL_VALUE', { ...args, value });
  },
  // eslint-disable-next-line object-curly-newline
  internalPush({ state, commit, getters, dispatch },
    { chatId, field, value }) {
    if (!(chatId in state)) dispatch('createModalState', chatId);
    const args = { chatId, field };
    if (getters.getModalValue(args) === value) return;
    commit('PUSH_MODAL_VALUE', { ...args, value });
  },
  setInput({ dispatch }, { chatId, message }) {
    dispatch('internalSet', { chatId, value: message, field: 'input' });
  },
  setCMPredictions({ dispatch }, { chatId, value }) {
    dispatch('internalSet', { chatId, value, field: 'cannedMessageP' });
  },
  setMetadataPredictions({ commit }, { chatId, value }) {
    commit('SET_META_PREDICTION', { chatId, value });
  },
  setArticlePredictionUUID({ dispatch }, { chatId, value }) {
    dispatch('internalSet', { chatId, value, field: 'articlesUUID' });
  },
  setVisitorUploadFileCache({ commit }, { chatId, value }) {
    commit('SET_VISITOR_FILE', { chatId, value });
  },
  setModalVisible({ dispatch }, { chatId, status }) {
    dispatch('internalSet', { chatId, value: status, field: 'visible' });
  },
  setTabIndex({ dispatch }, { chatId, value }) {
    dispatch('internalSet', { chatId, value, field: 'tabIndex' });
  },
};

export default {
  namespaced: true,
  state: modalsState,
  getters: modalGetters,
  mutations,
  actions,
};
