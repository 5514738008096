import Vue from 'vue';

import {
  createSupChatModel,
  createSupChatRanker,
  deleteSupChatModel,
  deleteSupChatRanker,
  disableArticleRanker,
  getArticleRankers,
  getEnabledArticleRanker,
  getEnabledRanker,
  getPretrainedModels,
  getSupChatModels,
  getSupChatRankers,
  setEnabledArticleRanker,
  setEnabledRanker,
} from '@/api/apiList';

import { formatToIsoString } from '@/utils/generalUtils';

import gptReply from './gptReply';

const predictionsState = {
  fetchingState: {
    pretrainedModels: false,
    supchatModels: false,
    supchatRankers: false,
  },
  articleRankers: [],
  enabledArticleRankers: [],
  enabledRankers: {},
  pretrainedModels: [],
  supchatModels: [],
  supchatRankers: [],
  statsView: {},
};

const predictionsGetters = {
  filterPretrainedModels: (state) => (type) => state.pretrainedModels
    .filter((model) => model.type === type),
  filterSupChatModels: (state) => ({ modelType, tenantId }) => state.supchatModels
    .filter((model) => model.type === modelType && model.tenant === tenantId),
  filterSupChatRankers: (state) => ({ modelIds }) => state.supchatRankers
    .filter(({ model }) => modelIds.includes(model)),
  filterSupChatRankersByIds: (state) => ({ rankerIds }) => state.supchatRankers
    .filter(({ ranker_id: rankerId }) => rankerIds.includes(rankerId)),
  getPretrainedLanguageModels: (state, getters) => getters
    .filterPretrainedModels('transformer_language_model'),
  getPretrainedSentimentModels: (state, getters) => getters
    .filterPretrainedModels('sentiment_model'),
  getSupChatModelById: (state) => (modelId) => {
    const match = state.supchatModels.find((model) => model.model_id === modelId);
    return match;
  },
  getEnabledRankers: (state) => (modelId) => state.enabledRankers?.[modelId] || {},
  getRankersUsedByModel: (state, getters) => (modelId) => getters
    .filterSupChatRankers({ modelIds: [modelId] }),
  getShownRankerStats:
    (state) => ({ modelType, tenantId }) => state.statsView?.[tenantId]?.[modelType] || [],
  getArticleRankers: (state) => state.articleRankers,
  getEnabledArticleRankers: (state) => ({ tenantId }) => state.enabledArticleRankers
    .filter((ranker) => ranker.tenant === tenantId),
  getEnabledRankerIdsForModelType: (state, getters) => ({ modelType, tenantId }) => {
    // first get all relevant model ids
    const models = getters.filterSupChatModels({ tenantId, modelType });
    const modelIds = models.map(({ model_id: modelId }) => modelId);
    // now get all staged/active statuses for the models
    const statusObjs = modelIds.map((id) => getters.getEnabledRankers(id));

    // map all the active ranker ids
    const rankerIds = statusObjs
      .map(({ active_ranker_id: activeRankerId }) => activeRankerId)
      .filter((id) => id !== null);
    return rankerIds;
  },
  /**
   * @typedef {Object} EnabledPredictions
   * @property {boolean} article - Is article prediction enabled
   * @property {boolean} canned_message - Is CM prediction enabled
   * @property {boolean} metadata - Is metadata prediction enabled
   * @property {boolean} sentiment - Is sentiment prediction enabled
   * @property {boolean} summary - Is summary prediction enabled
   * @property {boolean} gptArticleReply - Is GPT reply enabled
   */
  /**
   * @returns {EnabledPredictions}
   */
  enabledPredictions: (state, getters, rootState) => ({ tenantId }) => {
    const {
      AI_COPILOT_ENABLED,
      AI_COPILOT_GPT_ENABLED,
      AI_COPILOT_SUPSEARCH_ENABLED,
    } = rootState.featureFlags;
    const enabledPredictions = {
      article: AI_COPILOT_SUPSEARCH_ENABLED,
      canned_message: AI_COPILOT_ENABLED,
      metadata: AI_COPILOT_ENABLED,
      sentiment: AI_COPILOT_ENABLED,
      summary: AI_COPILOT_GPT_ENABLED,
    };
    const requiresNoModels = ['summary'];
    const enabledTypes = Object.entries(enabledPredictions)
      .filter(([feature, isEnabled]) => isEnabled && !requiresNoModels.includes(feature))
      .map(([feature]) => feature);
    enabledTypes.forEach((modelType) => {
      enabledPredictions[modelType] = false;
      if (modelType === 'article') {
        enabledPredictions[modelType] = !!getters
          .getEnabledArticleRankers({ tenantId })?.length;
      } else {
        const availableModels = getters.filterSupChatModels({ tenantId, modelType });
        if (availableModels?.length) {
          enabledPredictions[modelType] = availableModels.some((model) => {
            const modelId = model.model_id;
            const enabledRanker = getters.getEnabledRankers(modelId);
            return typeof enabledRanker?.active_ranker_id === 'number';
          });
        }
      }
    });

    // now we can check if gptReply is enabled
    enabledPredictions.gptArticleReply = AI_COPILOT_GPT_ENABLED
      && AI_COPILOT_SUPSEARCH_ENABLED
      && enabledPredictions.article;

    return enabledPredictions;
  },
  getMetadataFieldsWithRankers: (state, getters) => ({ tenantId }) => {
    const isMetadataPredictionEnabled = getters.enabledPredictions({ tenantId }).metadata;
    if (!isMetadataPredictionEnabled) return [];
    const enabledRankerIds = Object.values(state.enabledRankers)
      .map((rankerObj) => rankerObj.active_ranker_id)
      .filter((status) => status !== null);
    if (!enabledRankerIds?.length) return [];
    const rankerDetails = getters.filterSupChatRankersByIds({ rankerIds: enabledRankerIds });
    const rankerModelIds = rankerDetails.map((ranker) => ranker.model);
    const availableModels = getters.filterSupChatModels({ tenantId, modelType: 'metadata' });
    const modelsInUse = availableModels.filter((model) => rankerModelIds.includes(model.model_id));
    return modelsInUse.map((model) => model.subtype);
  },
};

const mutations = {
  SET_FETCHING_STATUS(state, { task, status }) {
    Vue.set(state.fetchingState, task, status);
  },
  SET_PRETRAINED_MODEL(state, model) {
    const index = state.pretrainedModels
      .findIndex((existingModel) => existingModel.id === model.id);
    if (index >= 0) {
      state.pretrainedModels.splice(index, 1, model);
    } else state.pretrainedModels.push(model);
  },
  SET_SUPCHAT_MODEL(state, model) {
    const index = state.supchatModels
      .findIndex((existingModel) => existingModel.model_id === model.model_id);
    if (index >= 0) {
      state.supchatModels.splice(index, 1, model);
    } else state.supchatModels.push(model);
  },
  DELETE_SUPCHAT_MODEL(state, modelId) {
    const index = state.supchatModels
      .findIndex((existingModel) => existingModel.model_id === modelId);
    if (index >= 0) {
      state.supchatModels.splice(index, 1);
    }
  },
  SET_SUPCHAT_RANKER(state, ranker) {
    const index = state.supchatRankers
      .findIndex((existingRanker) => existingRanker.ranker_id === ranker.ranker_id);
    if (index >= 0) {
      state.supchatRankers.splice(index, 1, ranker);
    } else state.supchatRankers.push(ranker);
  },
  DELETE_SUPCHAT_RANKER(state, rankerId) {
    const index = state.supchatRankers
      .findIndex((existingRanker) => existingRanker.ranker_id === rankerId);
    if (index >= 0) {
      state.supchatRankers.splice(index, 1);
    }
  },
  SET_ENABLED_RANKER(state, { modelId, statusObj }) {
    Vue.set(state.enabledRankers, modelId, statusObj);
  },
  SET_ARTICLE_RANKER(state, ranker) {
    const index = state.articleRankers
      .findIndex((existingRanker) => existingRanker.id === ranker.id);
    if (index >= 0) {
      state.articleRankers.splice(index, 1, ranker);
    } else state.articleRankers.push(ranker);
  },
  SET_ENABLED_ARTICLE_RANKER(state, ranker) {
    const index = state.enabledArticleRankers
      .findIndex((existingRanker) => ![
        existingRanker.tenant === ranker.tenant,
        existingRanker.language === ranker.language,
        existingRanker.type === ranker.type,
      ].includes(false));
    if (index >= 0) {
      state.enabledArticleRankers.splice(index, 1, ranker);
    } else state.enabledArticleRankers.push(ranker);
  },
  DELETE_ENABLED_ARTICLE_RANKER(state, { tenantId, language }) {
    const index = state.enabledArticleRankers
      .findIndex((existingRanker) => ![
        existingRanker.tenant === tenantId,
        existingRanker.language === language,
        existingRanker.type === 'article',
      ].includes(false));
    if (index >= 0) {
      state.enabledArticleRankers.splice(index, 1);
    }
  },
  TOGGLE_RANKER_STATS_VIEW(state, { modelType, rankerId, tenantId }) {
    if (typeof state.statsView[tenantId] !== 'object') {
      Vue.set(state.statsView, tenantId, {});
    }
    if (typeof state.statsView[tenantId][modelType] !== 'object') {
      Vue.set(state.statsView[tenantId], modelType, []);
    }
    if (state.statsView[tenantId][modelType].includes(rankerId)) {
      const index = state.statsView[tenantId][modelType].indexOf(rankerId);
      state.statsView[tenantId][modelType].splice(index, 1);
    } else {
      state.statsView[tenantId][modelType].push(rankerId);
    }
  },
  RESET_RANKER_STATS_VIEW(state, { modelType, tenantId }) {
    if (typeof state.statsView?.[tenantId]?.[modelType] !== 'object') return;
    state.statsView[tenantId][modelType] = [];
  },
};

const actions = {
  ensurePretrainedModels({ dispatch, state }) {
    if (!state.pretrainedModels.length) {
      dispatch('fetchPretrainedModels');
    }
  },
  async fetchPretrainedModels({ commit, rootState }) {
    if (!rootState.featureFlags.AI_COPILOT_ENABLED) return;
    try {
      commit('SET_FETCHING_STATUS', { task: 'pretrainedModels', status: true });
      const models = await getPretrainedModels();
      models.forEach((model) => commit('SET_PRETRAINED_MODEL', model));
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'message.errorUnexpected',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('SET_FETCHING_STATUS', { task: 'pretrainedModels', status: false });
    }
  },
  ensureSupchatModels({ dispatch, state }) {
    if (!state.supchatModels.length) {
      dispatch('fetchSupChatModels');
    }
  },
  async fetchSupChatModels({ commit, dispatch, rootState }) {
    if (!rootState.featureFlags.AI_COPILOT_ENABLED) return;
    try {
      commit('SET_FETCHING_STATUS', { task: 'supchatModels', status: true });
      const models = await getSupChatModels();
      models.forEach((model) => {
        commit('SET_SUPCHAT_MODEL', model);
        dispatch('fetchEnabledRankers', model.model_id);
      });
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'message.errorUnexpected',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('SET_FETCHING_STATUS', { task: 'supchatModels', status: false });
    }
  },
  async addSupChatModel({ commit, dispatch }, newModel) {
    try {
      commit('SET_FETCHING_STATUS', { task: 'supchatModels', status: true });
      const model = await createSupChatModel(newModel);
      dispatch('fetchEnabledRankers', model.model_id);
      commit('SET_SUPCHAT_MODEL', model);
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'message.errorUnexpected',
        variant: 'danger',
      }, { root: true });
      throw error;
    } finally {
      commit('SET_FETCHING_STATUS', { task: 'supchatModels', status: false });
    }
  },
  async deleteSupChatModel({ commit }, modelId) {
    try {
      commit('SET_FETCHING_STATUS', { task: 'supchatModels', status: true });
      await deleteSupChatModel(modelId);
      commit('DELETE_SUPCHAT_MODEL', modelId);
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'message.errorUnexpected',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('SET_FETCHING_STATUS', { task: 'supchatModels', status: false });
    }
  },
  ensureSupChatRankers({ dispatch, state }) {
    if (!state.supchatRankers.length) {
      dispatch('fetchSupChatRankers');
    }
  },
  async fetchSupChatRankers({ commit, dispatch, rootState }, rankerId = '') {
    if (!rootState.featureFlags.AI_COPILOT_ENABLED) return;
    const task = `supchatRankers${rankerId}`;
    try {
      commit('SET_FETCHING_STATUS', { task, status: true });
      let result = await getSupChatRankers(rankerId);
      if (rankerId !== '') result = [result];
      result.forEach((ranker) => {
        commit('SET_SUPCHAT_RANKER', ranker);
        // attempt to check if our model is currently training
        const hasError = ranker?.['ai-status']?.task_error_message || ranker.error_msg;
        if (!hasError && !ranker?.['ai-status']?.ready_for_use === true) {
          setTimeout(() => dispatch('fetchSupChatRankers', ranker.ranker_id), 60_000);
        }
      },
      );
    } catch (error) {
      if (error?.response?.status === 404) {
        // the user probably deleted the ranker while it was still training
        // this is probably ok. The polling stops since we use setTimeout
      } else {
        commit('errorDisplay/ADD_MSG', {
          message: 'message.errorUnexpected',
          variant: 'danger',
        }, { root: true });
      }
    } finally {
      commit('SET_FETCHING_STATUS', { task, status: false });
    }
  },
  async createSupChatRanker({ commit, dispatch }, {
    model, name, periodStart, periodEnd,
  }) {
    try {
      const newRanker = {
        model,
        name,
        periodStart: formatToIsoString(periodStart),
        periodEnd: formatToIsoString(periodEnd),
      };
      commit('SET_FETCHING_STATUS', { task: 'supchatRankers', status: true });
      const ranker = await createSupChatRanker(newRanker);
      commit('SET_SUPCHAT_RANKER', ranker);
      commit('SET_FETCHING_STATUS', { task: `supchatRankers${ranker.ranker_id}`, status: true });
      setTimeout(() => dispatch('fetchSupChatRankers', ranker.ranker_id), 30_000);
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'message.errorUnexpected',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('SET_FETCHING_STATUS', { task: 'supchatRankers', status: false });
    }
  },
  async deleteSupChatRanker({ commit }, rankerId) {
    try {
      commit('SET_FETCHING_STATUS', { task: 'supchatRankers', status: true });
      await deleteSupChatRanker(rankerId);
      commit('DELETE_SUPCHAT_RANKER', rankerId);
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'message.errorUnexpected',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('SET_FETCHING_STATUS', { task: 'supchatRankers', status: false });
    }
  },
  async fetchEnabledRankers({ commit, dispatch, rootState }, modelId) {
    if (!rootState.featureFlags.AI_COPILOT_ENABLED) return;
    const task = `enabledRankers${modelId}`;
    try {
      commit('SET_FETCHING_STATUS', { task, status: true });
      const statusObj = await getEnabledRanker(modelId);
      commit('SET_ENABLED_RANKER', { modelId, statusObj });

      // if target is not null then we fetch the status again in 10 seconds
      if (typeof statusObj?.target_ranker_id === 'number') {
        setTimeout(() => dispatch('fetchEnabledRankers', modelId), 10_000);
      }
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'message.errorUnexpected',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('SET_FETCHING_STATUS', { task, status: false });
    }
  },
  async setEnabledRankers({ commit, dispatch }, { modelId, rankerId }) {
    const task = `enabledRankers${modelId}`;
    try {
      commit('SET_FETCHING_STATUS', { task, status: true });
      await setEnabledRanker({ modelId, rankerId });
      setTimeout(() => dispatch('fetchEnabledRankers', modelId), 1000);
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'message.errorUnexpected',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('SET_FETCHING_STATUS', { task, status: false });
    }
  },
  async disableRanker({ commit, dispatch }, modelId) {
    const task = `enabledRankers${modelId}`;
    try {
      commit('SET_FETCHING_STATUS', { task, status: true });
      await setEnabledRanker({ modelId, rankerId: null });
      setTimeout(() => dispatch('fetchEnabledRankers', modelId), 1000);
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'message.errorUnexpected',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('SET_FETCHING_STATUS', { task, status: false });
    }
  },
  ensureArticleRankers({ dispatch, state }) {
    if (!state.articleRankers.length) {
      dispatch('fetchArticleRankers');
    }
  },
  async fetchArticleRankers({ commit, dispatch, rootState }) {
    if (!rootState.featureFlags.AI_COPILOT_SUPSEARCH_ENABLED) return;
    const task = 'articleRankers';
    try {
      commit('SET_FETCHING_STATUS', { task, status: true });
      const rankers = await getArticleRankers();
      rankers.forEach((ranker) => {
        commit('SET_ARTICLE_RANKER', ranker);
      });
      setTimeout(() => dispatch('getEnabledArticleRankers'), 1000);
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'message.errorUnexpected',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('SET_FETCHING_STATUS', { task, status: false });
    }
  },
  async ensureEnabledArticleRankers({ dispatch, state }) {
    if (!state.enabledArticleRankers.length) {
      dispatch('getEnabledArticleRankers');
    }
  },
  async getEnabledArticleRankers({ commit, rootState }) {
    if (!rootState.featureFlags.AI_COPILOT_SUPSEARCH_ENABLED) return;
    const task = 'enabledArticleRankers';
    try {
      commit('SET_FETCHING_STATUS', { task, status: true });
      const rankers = await getEnabledArticleRanker();
      rankers.forEach((ranker) => {
        commit('SET_ENABLED_ARTICLE_RANKER', ranker);
      });
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'message.errorUnexpected',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('SET_FETCHING_STATUS', { task, status: false });
    }
  },
  async setEnabledArticleRankers({ commit, dispatch }, {
    rankerId, tenantId, type, language,
  }) {
    const task = 'enabledArticleRankers';
    try {
      commit('SET_FETCHING_STATUS', { task, status: true });
      const enabledRanker = await setEnabledArticleRanker({
        rankerId, tenantId, type, language,
      });
      commit('SET_ENABLED_ARTICLE_RANKER', enabledRanker);
      setTimeout(() => dispatch('getEnabledArticleRankers'), 1000);
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'message.errorUnexpected',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('SET_FETCHING_STATUS', { task, status: false });
    }
  },
  async disableArticleRanker({ commit }, { tenantId, language }) {
    const task = 'enabledArticleRankers';
    try {
      commit('SET_FETCHING_STATUS', { task, status: true });
      await disableArticleRanker({ tenantId, language });
      commit('DELETE_ENABLED_ARTICLE_RANKER', { tenantId, language });
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'message.errorUnexpected',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('SET_FETCHING_STATUS', { task, status: false });
    }
  },
};

export default {
  namespaced: true,
  state: predictionsState,
  getters: predictionsGetters,
  mutations,
  actions,
  modules: { gptReply },
};
